import { Checkbox } from '@veneer/core';
import React, { memo, useContext, useEffect, useState } from 'react';
import { ConstraintsEffectEnum } from '../../../config/ConstraintsEnums';
import { FeatureEffectivePolicyEnum } from '../../../config/FeatureEnums';
import { SubFeatureAccessModeEnum } from '../../../config/SubFeatureEnums';
import EditDataContext from '../../../context/EditDataContext';
import LocalizeHelper from '../../../helper/LocalizeHelper';
import TempDataHelper from '../../../helper/TempDataHelper';
import { CheckBoxWrap, InputWrap } from './Styles';

const CheckBoxControl = (props) => {
  const { id, isChecked, isControlledPolicy, subfeaturesdata, isActionInProgress = false } = props;
  const { getDeviceSettingsLocString } = LocalizeHelper();
  const { getRulesEffect } = TempDataHelper();

  const { onUpdateTempData, tempRulesEffect } = useContext(EditDataContext);

  const [enabled, setEnabled] = useState(false);
  const [effectVal, setEffectVal] = useState();
  const label = getDeviceSettingsLocString(id, 'overwrite_checkbox');

  const isDisable =
    effectVal &&
    (effectVal === ConstraintsEffectEnum.ENABLE || effectVal === ConstraintsEffectEnum.SET)
      ? true
      : isChecked && isChecked
      ? isChecked
      : isControlledPolicy === FeatureEffectivePolicyEnum.controlledPolicy &&
        subfeaturesdata[0].accessMode === SubFeatureAccessModeEnum.READWRITE_DISABLE
      ? true
      : false;

  useEffect(() => {
    const ruleEffect = getRulesEffect(tempRulesEffect, subfeaturesdata[0].settingsName).effect;
    setEffectVal(ruleEffect);
  }, [tempRulesEffect]);
  const handleOnchange = (value) => {
    setEnabled(value);

    onUpdateTempData({
      settingsName: subfeaturesdata[0].settingsName,
      fleetValue: value,
      attribute: subfeaturesdata[0].attribute,
      error: false,
    });
  };

  const disabledOrNot = !isDisable || !isActionInProgress ? false : true;
  return (
    <CheckBoxWrap>
      <InputWrap>
        <Checkbox
          label={label}
          checked={enabled}
          disabled={disabledOrNot}
          onChange={({ target }) => handleOnchange(target.checked)}
        />
      </InputWrap>
    </CheckBoxWrap>
  );
};

export default memo(CheckBoxControl);