import { FeatureIdEnum } from '../../../src/config/FeatureEnums';
import LocalizeHelper from '../../../src/helper/LocalizeHelper';

export const PermissionSetsTableDataHelper = (subFeatureId, value) => {
  const { getDeviceTableColumnsLocString } = LocalizeHelper();
  if (!Array.isArray(value)) {
    return { PermissionSetsTableData: [] };
  }
  const isTypeCustom = value.map((data) => (data.isBuiltIn === 'true' ? 'Device Default' : 'Custom'));
  const permission = value.map((data) => data.localizedName);
  const localizePermission = permission.map((data) => getDeviceTableColumnsLocString(FeatureIdEnum.AccessControl, data));
  const combinedRoles = value.map((data, index) => ({
    permission: localizePermission[index] === '' ? permission[index] : localizePermission[index],
    type: isTypeCustom[index],
  }));
  return { PermissionSetsTableData: combinedRoles };
};