import { tableIdMapping } from '../../config/tableIdMap';
import LocalizeHelper from '../../helper/LocalizeHelper';
import { SubfeatureIdEnum } from '../../config/SubFeatureEnums';

let permissionForSetsRoleIds = [];

const useTableHead = (subFeatureId, value) => {
  const { getDeviceTableColumnsLocString } = LocalizeHelper();
  const processValue = (value) => {
    if (Array.isArray(value)) {
      return value.map(item => {
        if (typeof item === 'object') {
          return { columnId: item.localizedName };
        }
        return { columnId: item };
      });
    } else if (typeof value === 'object') {
      return [{ columnId: value.localizedName }];
    }
    return [];
  };
  const roleIds = processValue(value);
  if (subFeatureId === SubfeatureIdEnum.PermissionSets) {
  permissionForSetsRoleIds = roleIds.filter(role => {
      return role.columnId ;
    });
  }

  const generateTableHead = (subFeatureId, tableIdMapping) => {
    const tableData = tableIdMapping[subFeatureId];

    if (!tableData) {
      return [];
    }
    let permissionIds;
    if (subFeatureId === SubfeatureIdEnum.ControlPanelAccessTable || subFeatureId === SubfeatureIdEnum.EWS_AccessControlTable) {
    const dynamicRoleIds = permissionForSetsRoleIds.map(role => role.columnId);
    permissionIds = [...tableData.ids, ...dynamicRoleIds];
    } else {
      permissionIds = tableData.ids;
    }
    const tableHead = permissionIds.map((data) => {
      const localizedLabel = getDeviceTableColumnsLocString(tableData.label, data);
      return {
        id: data,
        label: localizedLabel || roleIds.find(role => role.columnId === data)?.columnId || data,
      };
    });
    return tableHead;
  };
  const tableHead = generateTableHead(subFeatureId, tableIdMapping);
  return { tableHead };
};

export default useTableHead;