export const PermissionSetsTableMockData = {
  cdmData: {
    roles: [
      {
        localizedName: 'Device Administrator',
        roleId: '42e5e2d7-6c01-42b8-b086-ee8a1a2c25de',
        isBuiltIn: true,
      },
      {
        localizedName: 'Device Guest',
        roleId: 'a69a546c-dd68-4e4b-8302-97cd6471a0a4',
        isBuiltIn: true,
      },
      {
        localizedName: 'Device User',
        roleId: '0a52b510-5db2-49f2-b95d-7f5c37c50235',
        isBuiltIn: true,
      },  
    ],
  },
};

export const accessControlTableData = {
  cdmData: {
    permissions: [
      {
        permissionId: '34876b06-05be-4044-b61c-40cca9dfe4cb',
        localizedName: 'Reports',
        childPermissions: [
          'f22bab25-0bde-4983-9e28-24e536f13981',
          '84fc50f6-338d-4f5c-baba-e3a90418f364',
          '5d1f0b3f-131e-4901-9807-a17d21ed11ba',
          '8591cb93-584c-43db-af22-eb9a817add9c',
          'd13fe446-6403-40c4-8f95-c734978d3dd3',
          '7bb1b399-d731-4f3e-a013-82ce9ea9434f',
          'c4fc5dcf-d03f-46e0-8e42-085ec5c3c108',
          '7e162dc5-a9af-4af2-b3a3-48c2d6e6bb7b',
          'a12676bb-aa09-44bf-9608-a8d14cde50d7',
          '81fbf819-ac36-481d-8736-c66a20a3ce75',
          '978af7de-4501-4709-b4e6-ecc056d41917',
          'ff217942-3d41-4c9d-b270-a49b987fe104',
        ],
        configuredAuthenticationMethod: '00000000-0000-0000-0000-000000000000',
        parentPermission: '34876b06-05be-4044-b61c-40cca9dfe4cb',
      },
      {
        permissionId: '87550e5e-f927-11df-950b-00306e48bff7',
        localizedName: 'Print from Job Storage',
        childPermissions: [
          'd544b0ba-a3fb-4911-a82b-bf4f891b3308',
          'a4dfaedc-7724-48ea-948a-5cd7b11407f0',
          '535ea693-db6d-4beb-b548-f1693460eab3',
        ],
        configuredAuthenticationMethod: '00000000-0000-0000-0000-000000000000',
        parentPermission: '87550e5e-f927-11df-950b-00306e48bff7',
      },
      {
        permissionId: '56ce9217-377d-4d5c-a950-a2ad37c07882',
        localizedName: 'Job Log and Active Jobs',
        childPermissions: [
          '3c40cf32-1d3d-4051-9ba2-0a7b839b0288',
          '4068badd-7fa0-4c8f-b875-bf7e04dec26e',
          'ef4dbcd9-34d5-46e2-af53-1bacda9e2a34',
        ],
        configuredAuthenticationMethod: '00000000-0000-0000-0000-000000000000',
        parentPermission: '56ce9217-377d-4d5c-a950-a2ad37c07882',
      },
      {
        permissionId: '3dfe6950-5cf9-41c2-a3b2-6154868ab45d',
        localizedName: 'Settings',
        childPermissions: [
          'a062b6d9-8475-4663-8bbf-c9ed15e8d460',
          'defd075a-d6a7-498e-9efb-4b9465a8c091',
          'fdc59fb8-3a54-426d-9f5a-2d9087233bb0',
          'b532012c-4017-472a-97d7-625f628bf75b',
          '1a2d31a0-b6c8-4497-a708-707792bd2609',
          '74425807-8557-49ae-820c-ce203f15e96b',
          'cdd8157e-c605-473d-939b-98994607a436',
          '4fdcd8b2-6e7e-44da-924a-85981a727c1b',
          '4692a241-8998-4aa8-9d31-1f8ccb43ea14',
          '3c06acc4-f0e9-4248-8933-4aa500cee7b8',
          'dceabb35-81d0-4c5f-a2f8-4c9c318b043b',
          'fbd10c44-f550-498a-bfa0-b7e634afa551',
          '7389ff49-20e5-41ff-9b96-5c1df60d7e67',
          '07799ea1-52ea-4cc8-8dbf-1458dc2f39d1',
          'd41cc382-c023-46dc-b722-4178a599901a',
          '7b8e9297-1c88-4316-b1c8-ed7a5b8eb4c7',
          '2565f335-e4ad-4cde-966d-30d9f3f1999f',
          'c980a35a-3e93-4971-b8c6-f76cbfbeedcf',
          'a639160a-7b18-4c02-b4a4-00b9026f00d6',
          '52099d81-6fd5-481f-84ef-588c8ce39a3c',
          '74d83bcd-0cd0-4f89-aca5-39df93cb0b0d',
          '4d1dc259-0d38-4bfd-b121-b11c22bc67bc',
          '965dabb4-8d1a-42df-943a-74c368a4c144',
          'eca4c819-4f03-458c-9aff-62f4be730e0b',
          'bca81cac-3f14-4f32-ac00-185dd58dc3e1',
          '69dd2781-34e5-4abe-b170-c7bf2ef5696c',
          'aa531833-f8e7-453b-b81d-fb827098c5f0',
          '91241b7e-cc42-11d7-b4b1-00306e482828',
          'cca06313-14f0-4e13-a2cf-97c38abb1d73',
          '68147198-5521-46ed-bec1-1b395f993f1a',
          '5f85357c-1202-4d1b-8445-e0224456c57a',
          '3a9de7c7-5e0d-469c-849a-5920881810b4',
        ],
        configuredAuthenticationMethod: '00000000-0000-0000-0000-000000000000',
        parentPermission: '3dfe6950-5cf9-41c2-a3b2-6154868ab45d',
      },
      {
        permissionId: '1d370ecf-fb74-44ae-8934-39740a6911ed',
        localizedName: 'Support Tools',
        childPermissions: [
          'a9e3da1b-8173-419f-bd6a-2cc325567c4c',
          'e4835c1f-7f0e-446b-9fc2-f18ef145698f',
          '0d561c63-8717-4dbe-9b45-b7dae135f6b1',
          'efb5ad4c-591c-4c94-bacf-be5d87c917a3',
        ],
        configuredAuthenticationMethod: '00000000-0000-0000-0000-000000000000',
        parentPermission: '1d370ecf-fb74-44ae-8934-39740a6911ed',
      },
      {
        permissionId: 'a5e59604-d216-4977-a901-4774fcacbcb4',
        localizedName: 'Supplies',
        childPermissions: [],
        configuredAuthenticationMethod: '00000000-0000-0000-0000-000000000000',
        parentPermission: 'a5e59604-d216-4977-a901-4774fcacbcb4',
      },
      {
        permissionId: 'A935C131-CBE6-4d09-9AC2-624C12A9033B',
        localizedName: 'HP Command Center',
        childPermissions: [],
        configuredAuthenticationMethod: '00000000-0000-0000-0000-000000000000',
        parentPermission: 'a935c131-cbe6-4d09-9ac2-624c12a9033b',
      },
      {
        permissionId: 'cedab422-33b3-4638-b6a1-604e54525215',
        localizedName: 'Copy',
        childPermissions: [
          '7deecd46-5c05-4ce7-9c23-0c6fa1d5fc01',
          'cc00c027-fe28-48fc-b26c-a83d67da66ea',
          '8a31ba78-dd0d-11d9-83a3-00306e2bb999',
          '1fc3f42a-f887-461c-a27a-66a73156308a',
          '6b27ea0c-5311-4ce7-b0a8-aa4d21afe76b',
          '57325ed2-49cd-4253-86f4-dc9af7103bda',
        ],
        configuredAuthenticationMethod: '00000000-0000-0000-0000-000000000000',
        parentPermission: 'cedab422-33b3-4638-b6a1-604e54525215',
      },
      {
        permissionId: 'e402dfff-566a-45c9-a0d3-18350436666e',
        localizedName: 'Trays',
        childPermissions: ['e402dfff-566a-45c9-a0d3-18350436666e'],
        configuredAuthenticationMethod: '00000000-0000-0000-0000-000000000000',
        parentPermission: '',
      },
      {
        permissionId: '12642a24-5e74-41a2-a154-20d20436abee',
        localizedName: 'Print from USB Drive',
        childPermissions: [],
        configuredAuthenticationMethod: '00000000-0000-0000-0000-000000000000',
        parentPermission: '12642a24-5e74-41a2-a154-20d20436abee',
      },
      {
        permissionId: 'bf9b23f7-69c7-4c9e-8541-69982811242a',
        localizedName: 'Printing',
        childPermissions: [
          '68bccf58-bfd7-422e-9788-29a36dc061cd',
          'ee0db66a-dea1-4a9c-8f32-61930b6385aa',
        ],
        configuredAuthenticationMethod: '00000000-0000-0000-0000-000000000000',
        parentPermission: '',
      },
      {
        permissionId: '44aa632b-cfa3-4c10-8cab-697a9bef610b',
        localizedName: 'Fax',
        childPermissions: [
          '922cd28d-77a8-47a8-85c4-f117f5f69c82',
          '8b99a8ad-9432-48a1-baca-22b0df6c65e4',
          'c33fdc04-682f-43ab-9f70-fdedfdc1d74b',
          '016d46c6-5a3a-4646-910d-0ede122b949e',
        ],
        configuredAuthenticationMethod: '00000000-0000-0000-0000-000000000000',
        parentPermission: '44aa632b-cfa3-4c10-8cab-697a9bef610b',
      },
      {
        permissionId: 'b8460c9e-43c8-4290-a0f8-8ce450867f09',
        localizedName: 'Email',
        childPermissions: [
          '5a707440-966b-4598-9c9b-29b08838d9a7',
          'b35a7519-0680-46cd-834c-3096ab8a8692',
          'eb70fc52-81a7-4261-9978-f31c09ed87dc',
          '1502c3b6-db02-4010-ab27-a9a11f353ff0',
          'e56b78b2-5081-4473-9761-f90c79cca974',
          '1d073e3c-7bd1-4269-b029-4e26c17c65b8',
          'd8a96619-9aba-4249-afac-b2db90d0ca8b',
          '5af2a754-d27c-40a4-a56d-2e7191def383',
          'd5e988c7-c39c-4e77-b53f-51c6fdea210a',
        ],
        configuredAuthenticationMethod: '00000000-0000-0000-0000-000000000000',
        parentPermission: 'b8460c9e-43c8-4290-a0f8-8ce450867f09',
      },
      {
        permissionId: '900a0d59-533a-497e-8e89-2b5bc898d5cc',
        localizedName: 'Contacts',
        childPermissions: [
          'ed979c88-86e4-422a-9842-6da6d53b431b',
          '1047e094-c564-4bcc-8a14-370bfddfb796',
          '6d777dcb-f62b-4cec-b536-c39078e14fc5',
        ],
        configuredAuthenticationMethod: '00000000-0000-0000-0000-000000000000',
        parentPermission: '900a0d59-533a-497e-8e89-2b5bc898d5cc',
      },
      {
        permissionId: 'a3d696df-b7ff-4d3d-9969-5cd7f18c0c92',
        localizedName: 'Scan to SharePoint®',
        childPermissions: ['19363c7c-235e-4c78-8be3-fb4673d59b25'],
        configuredAuthenticationMethod: '00000000-0000-0000-0000-000000000000',
        parentPermission: 'a3d696df-b7ff-4d3d-9969-5cd7f18c0c92',
      },
      {
        permissionId: '65acca51-619d-4e29-b1d0-6414e52f908b',
        localizedName: 'Scan to Network Folder',
        childPermissions: [
          'ee19ffb2-d93c-42ab-a23b-b868a63304a3',
          '3ecf76a7-4ebe-4265-8cdf-22f4f359daf9',
          'f753d9a7-c4fc-47c8-abd3-49d901528f29',
          'f69557b5-82f6-4269-894c-1b4046a0a92a',
        ],
        configuredAuthenticationMethod: '00000000-0000-0000-0000-000000000000',
        parentPermission: '65acca51-619d-4e29-b1d0-6414e52f908b',
      },
      {
        permissionId: '09866970-7133-404f-bb20-440b9148e8e2',
        localizedName: 'Scan to USB Drive',
        childPermissions: [
          '1bd63e89-82c9-4360-82c6-1fe9b4241247',
          '4044fe20-84a3-4314-a708-35626144609b',
          '00aed7aa-fc3b-4d18-81dd-e46365576f6a',
        ],
        configuredAuthenticationMethod: '00000000-0000-0000-0000-000000000000',
        parentPermission: '09866970-7133-404f-bb20-440b9148e8e2',
      },
      {
        permissionId: 'd6c8dbb4-0cea-4147-b8a7-0cffd9c3ca90',
        localizedName: 'Scan to Job Storage',
        childPermissions: [],
        configuredAuthenticationMethod: '00000000-0000-0000-0000-000000000000',
        parentPermission: 'd6c8dbb4-0cea-4147-b8a7-0cffd9c3ca90',
      },
      {
        permissionId: '573619b2-7527-48b7-9ef7-ea0dcca519b5',
        localizedName: 'Remote Scan Request',
        childPermissions: [],
        configuredAuthenticationMethod: '00000000-0000-0000-0000-000000000000',
        parentPermission: '573619b2-7527-48b7-9ef7-ea0dcca519b5',
      },
      {
        permissionId: 'c9b33fe6-fc66-4cf3-b206-1961b1b8bd09',
        localizedName: 'Troubleshooting',
        childPermissions: [
          '7bf5228d-64db-42c1-aa4d-88f2d18902c8',
          '9a67e94b-38dc-4e1f-b83f-1f76919ad1fc',
          '030c7f90-a030-4ab9-8b0d-e4157eaefe33',
          '9f7b6180-7fa1-49f0-b949-4d2e212759b7',
          '50d3cdc8-92d9-416d-abc4-15ef48353ac9',
        ],
        configuredAuthenticationMethod: '00000000-0000-0000-0000-000000000000',
        parentPermission: 'c9b33fe6-fc66-4cf3-b206-1961b1b8bd09',
      },
      {
        permissionId: '8e03369c-5e1d-4db2-aa95-f8aa176ad4dd',
        localizedName: 'Information',
        childPermissions: [
          '286b34e5-c683-41f7-97db-d0789d09d8fb',
          '862a91f4-7f00-4e1d-9c9e-ca39fb96625f',
          '4195be24-b6b9-4606-8231-e9f0fbe58082',
          '03b40393-3f56-497d-b625-c4e9fe947cbb',
          'b64ab78b-109c-4d28-bbaa-9d38b170f39c',
          '1228a6e0-7bc4-43c3-bd7a-7c9a41403416',
        ],
        configuredAuthenticationMethod: '00000000-0000-0000-0000-000000000000',
        parentPermission: '8e03369c-5e1d-4db2-aa95-f8aa176ad4dd',
      },
      {
        permissionId: '6a8ce243-4b14-4940-a00f-d80fe4532f48',
        localizedName: 'Networking',
        childPermissions: [],
        configuredAuthenticationMethod: '00000000-0000-0000-0000-000000000000',
        parentPermission: '6a8ce243-4b14-4940-a00f-d80fe4532f48',
      },
      {
        permissionId: '7452e5a1-2537-422b-9424-8c4614561d4e',
        localizedName: 'Supplies',
        childPermissions: [],
        configuredAuthenticationMethod: '00000000-0000-0000-0000-000000000000',
        parentPermission: '7452e5a1-2537-422b-9424-8c4614561d4e',
      },
      {
        permissionId: 'fe81f04e-a67f-4036-9f44-45ab8c51e298',
        localizedName: 'HP Web Services',
        childPermissions: [],
        configuredAuthenticationMethod: '00000000-0000-0000-0000-000000000000',
        parentPermission: 'fe81f04e-a67f-4036-9f44-45ab8c51e298',
      },
      {
        permissionId: '8105cca1-dca3-498b-9851-e30e3c86c604',
        localizedName: 'General',
        childPermissions: [
          'ab2a46af-9e0e-40e6-8f78-6f2d90552e96',
          '623737ae-245f-48b9-a920-49c62d12381e',
          'c8e0feff-e9be-471a-8c52-75036e7ddabe',
          '313062db-30b2-46c7-aee2-d17b306358d1',
          '236906f8-2a77-4c74-b798-db600b8d5b21',
          'f9e0ead2-72fd-4c05-abfd-afb9c7856674',
          '6646e5ba-44cf-4bbe-9965-43a7207575d2',
          'b14973a4-292d-4a87-8601-418da34c7d84',
          '75ede14f-6df7-402e-83f6-935fe361fa7c',
          '2022f59d-6345-4112-8757-d4d6f6f32cf1',
          '35cd52af-9c9f-41d5-9127-3d8257192fe7',
          'b0b58bbd-0aaa-4aa4-8376-4c7d357b30eb',
        ],
        configuredAuthenticationMethod: '00000000-0000-0000-0000-000000000000',
        parentPermission: '8105cca1-dca3-498b-9851-e30e3c86c604',
      },
      {
        permissionId: 'ce752501-2c37-4970-86ca-a71c6278d7c9',
        localizedName: 'Security',
        childPermissions: [
          'e7092690-73ad-41c7-82e4-69b3303f5b1c',
          '7e6233fe-d9fe-42a2-8aa2-54d4cee0fd92',
          '915f2966-e444-4bf5-bfc9-a1426d69d70a',
          '65b90df4-b001-4dfc-8e8f-a417ca6d1c05',
          '3e3a2318-8e7d-43ae-b091-16f26f197d0c',
          '003a8af8-284f-4c92-9990-969b1cb14af5',
          '0f176eaf-536d-40e9-8d67-9b4ca0a4e9e3',
        ],
        configuredAuthenticationMethod: '00000000-0000-0000-0000-000000000000',
        parentPermission: 'ce752501-2c37-4970-86ca-a71c6278d7c9',
      },
      {
        permissionId: '183ad723-1ce3-4da1-999c-ba546a7dd7fa',
        localizedName: 'Copy/Print',
        childPermissions: [
          '4e2c5b64-0b2e-45e6-b4b2-697ae54783ba',
          '2d954577-376c-41e6-82d1-cc51e2e1ae02',
          '12f36ee6-d6dd-4213-96a7-7d4fb4ac67ae',
          '032dde52-eba1-4ee4-97bc-2a6c2c492f78',
          '0fc15b0b-005e-46b9-b8d3-5e681e75eab4',
          '19a2b782-b1f6-4719-b983-820b8d5ee43c',
          '4ca5433c-b867-4bb7-9086-dd95be39408d',
          'f346c979-3090-4b14-8202-d5411a61c7de',
        ],
        configuredAuthenticationMethod: '00000000-0000-0000-0000-000000000000',
        parentPermission: '183ad723-1ce3-4da1-999c-ba546a7dd7fa',
      },
      {
        permissionId: '7fa7a12e-2df5-4dee-9849-55baecb89f06',
        localizedName: 'Fax',
        childPermissions: [
          '15b704f8-2a01-43bf-bc13-3356526291a6',
          '8f85e851-3ac9-4721-a2f0-406e03b7172b',
          'd526371c-57a7-4018-b88a-3751d14560e7',
          '80a6605f-b4ad-4f3d-a30d-fdd0b6dbd443',
          'd4cd0964-479e-401a-bc61-12b9f91be0ca',
        ],
        configuredAuthenticationMethod: '00000000-0000-0000-0000-000000000000',
        parentPermission: '7fa7a12e-2df5-4dee-9849-55baecb89f06',
      },
      {
        permissionId: '03fe3bc9-85aa-4331-b8f6-633842527cc9',
        localizedName: 'Scan/Digital Send',
        childPermissions: [
          '8c15fe0e-9bc9-4ba7-aa6f-1f435267b8c6',
          'de440e2f-c0ef-4dc0-8be1-9ac46e5f8936',
          'c925c01b-a82d-4b09-bb92-25d2be2dc55c',
          'a14f9e65-562d-4a54-92e9-cac2aa2a3b02',
          '57322a3d-805c-45f1-a384-3e0b81f52977',
          '4cbd5f66-70e6-4aef-87fe-c095654de906',
          'b54ffebc-fe7e-4df1-b9cf-949699ecb4bc',
          'ca21e9ff-613f-4e40-ad89-a3cfc91c6724',
        ],
        configuredAuthenticationMethod: '00000000-0000-0000-0000-000000000000',
        parentPermission: '03fe3bc9-85aa-4331-b8f6-633842527cc9',
      },
    ],
  },
};

export const permissionIdForEWS = {
  permissionId: {
"c9b33fe6-fc66-4cf3-b206-1961b1b8bd09": "EWS_troubleshooting",
"8e03369c-5e1d-4db2-aa95-f8aa176ad4dd": "EWS_information",
"6a8ce243-4b14-4940-a00f-d80fe4532f48": "EWS_networking",
"7452e5a1-2537-422b-9424-8c4614561d4e": "Ews_supplies",
"fe81f04e-a67f-4036-9f44-45ab8c51e298": "EWS_hp_web_services",
"8105cca1-dca3-498b-9851-e30e3c86c604": "EWS_general",
"ce752501-2c37-4970-86ca-a71c6278d7c9": "EWS_security",
"183ad723-1ce3-4da1-999c-ba546a7dd7fa": "EWS_copy_print",
"7fa7a12e-2df5-4dee-9849-55baecb89f06": "EWS_fax",
"03fe3bc9-85aa-4331-b8f6-633842527cc9": "EWS_scan_digital_send"
  }
};