import React, { useState, useEffect } from 'react';
import { CategoryEnum } from '../../../../config/CategoryEnums';
import LocalizeHelper from '../../../../helper/LocalizeHelper';
import { FeatureContainer } from '../../Feature/FeatureContainer';
import * as Style from '../Styles';

export const CategorySection = (props) => {
  const { deviceCacheResult, deviceConfigData, categoryKey, entitlements , isWex} = props;
  const { getDeviceCategoryLocString, getDeviceFeatureLocString } = LocalizeHelper();
  const [sortedDeviceConfigData, setSortedDeviceConfigData] = useState([]);

  useEffect(() => {
    const sorted = deviceConfigData.slice().sort((a, b) => {
  return getDeviceFeatureLocString(a.id, 'name').toLowerCase() <  getDeviceFeatureLocString(b.id, 'name').toLowerCase()? -1:  1;});
    setSortedDeviceConfigData(sorted);
  }, [deviceConfigData]);

  return (
    <Style.CategoryCard isWex = {isWex}>
      <Style.CategoryAccordion
      isWex = {isWex}
        items={[
          {
            id: `${CategoryEnum[categoryKey.charAt(0).toUpperCase() + categoryKey.slice(1)]}-body`,
            class: 'category-items',
            expanded: false,
            header: {
              centralArea: (
                <Style.AccordionTitle>
                  {getDeviceCategoryLocString(categoryKey)}
                </Style.AccordionTitle>
              ),
            },
            content: (
              <FeatureContainer
              deviceCacheResult={deviceCacheResult}
                dataModelFeatures={sortedDeviceConfigData}
                handleOnEditClick={props.handleOnEditClick}
                entitlements={entitlements}
                isWex = {isWex}
              />
            ),
          },
        ]}
        id={CategoryEnum[categoryKey.charAt(0).toUpperCase() + categoryKey.slice(1)]}
        className={'category-accordion'}
        behavior={'singleExpand'}
        data-testid={'id-single-mode-category-accordian'}
      />
    </Style.CategoryCard>
  );
};
