import { configureStore } from '@reduxjs/toolkit';
import { ThemeProvider, ToastProvider, useToast } from '@veneer/core';
import React,  { useMemo } from 'react';
import { Provider } from 'react-redux';
import packageInfo from '../package.json';
import { DeviceConfiguration } from '../src/components/Main/DeviceConfiguration';
import { ShellPropsProvider } from '../src/shellProps';
import rootReducer from '../src/store';
import { ShellProps } from './types/shell';
import resources from '../src/assets/locale';

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
});

declare global {
  interface Window {
    Shell: ShellProps;
  }
}

function WexComponent(props) {
  const { v1 } = window.Shell as ShellProps;
  const { localization, stack, useMockData, containerId, demoEnabled, apiPath, requestOptions, demoModeOptions } = props;
  const namespace = '@jarvis/react-ecp-device-details-configuration';
  const themeProviderProps = v1?.theme?.getThemeProviderProperties?.();
  const myAuthProvider = {
    getAccessToken: () => new Promise((resolve) => resolve('specify_valid_access_token_here')),
    getIDToken: () => new Promise((resolve) => resolve('')),
    onTokenExchangeRequired: () => new Promise((resolve) => resolve('')),
  };
  const TranslatorProvider = useMemo(
    () => localization?.getReactTranslatorProvider(React),
    [localization],
  );

  let makeAndModel: any[] = [];

  const mockLocalization = {
    language: 'en',
    country: 'US',
  };

  const showMultipleConfigUi = false;

  const showModal = false;

  const singleMakeAndModel = [
    {
      number: '3QA55V',
    },
  ];
  const wexDemoMode = useMockData ? useMockData : false;

  if (!props.showMultipleConfigUi) {
    makeAndModel.push({ number: props.deviceModelNumber });
  } else {
    makeAndModel = props.selectedData.map((x) => {
      return x.identity.makeAndModel;
    });
  }

  const CommonComponents = () => (
<ThemeProvider {...themeProviderProps}>
      <ToastProvider>
        <ShellPropsProvider
          id={packageInfo.name}
          ecpDeviceV55={sessionStorage.getItem('ecpDeviceV55') ?? false}
          ecpDeviceRolesPermissions={false}
          uxFramework={true}
          stack={useMockData ? 1 : stack}
          useToast={useToast}
          authProvider={useMockData ? myAuthProvider : v1.authProvider}
          showMultipleConfigUi={props.showMultipleConfigUi}
          selectedDeviceIdList={props.selectedDeviceIdList}
          handleUseToast={props.handleUseToast}
          showModal={props.showModal}
          setShowModal={props.setShowModal}
          localization={useMockData ? mockLocalization : localization}
          containerId={containerId}
        >
          <Provider store={store}>
            <DeviceConfiguration
              {...{ localization }}
              demoEnabled={demoEnabled}
              apiPath={apiPath}
              demoModeOptionsdemo={demoModeOptions}
              requestOptions={requestOptions}
              makeAndModel={useMockData ? singleMakeAndModel : makeAndModel}
              demoMode={wexDemoMode}
              useMockData={useMockData}
              {...props}
            />
          </Provider>
        </ShellPropsProvider>
      </ToastProvider>
    </ThemeProvider>
  );

  return (
    <section id={namespace}>
      {useMockData ? (
        <CommonComponents />
      ) : (
        <TranslatorProvider resources={resources}>
        <CommonComponents />
      </TranslatorProvider>
      )}
    </section>
  );
}

export default WexComponent;
