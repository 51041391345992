import {
  ConstraintsResourceEnum,
  ConstraintsSettingsNameEnum,
  ConstraintsSubFeatureIdEnum,
} from '../config/ConstraintsEnums';
import { DeviceCacheCdmDataEnum } from '../config/DeviceCacheCdmDataEnums';
import { DeviceCacheServiceGunEnum } from '../config/DeviceCacheServiceGunEnums';
import { FeatureIdEnum } from '../config/FeatureEnums';
import { FleetSvcPolicyAttributeId } from '../config/FleetServiceEnums';
import {
  SubFeatureAccessModeEnum,
  SubFeatureControlTypeEnum,
  SubFeatureCustomModeEnum,
  SubfeatureIdEnum,
  SubfeatureIndentEnum,
} from '../config/SubFeatureEnums';

export const DeviceDataModel = [
  {
    id: `${FeatureIdEnum.Control_Panel_Language}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Control_Panel_Language}`,
        attribute: `${FleetSvcPolicyAttributeId.Control_Panel_Language}.code`,
        type: `${SubFeatureControlTypeEnum.Dropbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'label',
        info: 'info',
        hint: 'hint',
        help: 'help',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Control_Panel_Language}`,
        resource: `${ConstraintsResourceEnum.ControlPanel_Configuration}`,
        settingsName: `${ConstraintsSettingsNameEnum.Control_Panel_Language}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.ControlPanelResource}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.CurrentLanguage}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.Date_Time_Format}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Date_Format}`,
        attribute: `${FleetSvcPolicyAttributeId.Date_Format}.date`,
        type: `${SubFeatureControlTypeEnum.Radio}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'date_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Date_Format}`,
        resource: `${ConstraintsResourceEnum.Clock_Configuration}`,
        settingsName: `${ConstraintsSettingsNameEnum.Date_Format}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.ClockConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.ClockDateFormat}`,
      },
      {
        id: `${SubfeatureIdEnum.Time_Format}`,
        attribute: `${FleetSvcPolicyAttributeId.Time_Format}.time`,
        type: `${SubFeatureControlTypeEnum.Radio}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'time_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Time_Format}`,
        resource: `${ConstraintsResourceEnum.Clock_Configuration}`,
        settingsName: `${ConstraintsSettingsNameEnum.Time_Format}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.ClockConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.ClockTimeFormat}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.Date_And_Time}`,
    configurable: false,
    visible: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Date_And_Time}`,
        attribute: `${FleetSvcPolicyAttributeId.Date_And_Time}.date-time`,
        type: `${SubFeatureControlTypeEnum.Dropbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'date_time_label',
        hint: 'date_time_hint',
        help: 'date_time_help',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Date_And_Time}`,
        resource: `${ConstraintsResourceEnum.Clock_Configuration}`,
        settingsName: `${ConstraintsSettingsNameEnum.System_Time}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.Time_Zone}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Time_Zone}`,
        attribute: `${FleetSvcPolicyAttributeId.Time_Zone}.timeZone`,
        type: `${SubFeatureControlTypeEnum.Dropbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'timezone_label',
        info: 'timezone_info',
        hint: 'timezone_hint',
        help: 'timezone_help',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Time_Zone}`,
        resource: `${ConstraintsResourceEnum.Clock_Configuration}`,
        settingsName: `${ConstraintsSettingsNameEnum.Time_Zone}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.ClockConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.ClockTimeZone}`,
      },
      {
        id: `${SubfeatureIdEnum.DayLight_Saving}`,
        attribute: `${FleetSvcPolicyAttributeId.Time_Zone}.auto-dst`,
        type: `${SubFeatureControlTypeEnum.Toggle}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        indent: SubfeatureIndentEnum.Indent_Level_1,
        label: 'daylight_label',
        info: 'daylight_info',
        hint: 'daylight_hint',
        help: 'daylight_help',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Time_Zone}`,
        resource: `${ConstraintsResourceEnum.Clock_Configuration}`,
        settingsName: `${ConstraintsSettingsNameEnum.Dst_Enabled}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.ClockConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.ClockDstEnabled}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.Default_Media_Size}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Default_Media_Size}`,
        attribute: `${FleetSvcPolicyAttributeId.Default_Media_Size}.media-size`,
        type: `${SubFeatureControlTypeEnum.Dropbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'label',
        info: 'info',
        hint: 'hint',
        help: 'help',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Default_Media_Size}`,
        resource: `${ConstraintsResourceEnum.JobTicketConfigDefaults_Print}`,
        settingsName: `${ConstraintsSettingsNameEnum.Def_Media_Size}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.PrintConfigurationDefault}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.PrintJobMediaSize}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.Default_Media_Type}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Default_Media_Type}`,
        attribute: `${FleetSvcPolicyAttributeId.Default_Media_Type}.media-type`,
        type: `${SubFeatureControlTypeEnum.Dropbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'label',
        info: 'info',
        hint: 'hint',
        help: 'help',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Default_Media_Type}`,
        resource: `${ConstraintsResourceEnum.JobTicketConfigDefaults_Print}`,
        settingsName: `${ConstraintsSettingsNameEnum.Def_Media_Type}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.PrintConfigurationDefault}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.PrintJobMediaType}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.Sleep_Delay}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Sleep_Delay}`,
        attribute: `${FleetSvcPolicyAttributeId.Sleep_Delay}.value`,
        type: `${SubFeatureControlTypeEnum.Textbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'label',
        hint: 'hint',
        help: 'help',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Sleep_Delay}`,
        resource: `${ConstraintsResourceEnum.PowerConfig}`,
        settingsName: `${ConstraintsSettingsNameEnum.Sleep_Timeout}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.PowerConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.PowerConfigSleepTimeout}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.Sleep_Schedule}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Sleep_Schedule_Weekly}`,
        attribute: `${FleetSvcPolicyAttributeId.Sleep_Schedule}.weekly`,
        type: SubFeatureControlTypeEnum.Table,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'sleep_Schedule_Weekly',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Sleep_Schedule_Weekly}`,
        resource: `${ConstraintsResourceEnum.SleepConfiguration}`,
        settingsName: `${ConstraintsSettingsNameEnum.Sleep_Schedule_Weekly}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.SleepConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.CdmData}`,
      },
      {
        id: `${SubfeatureIdEnum.Sleep_Schedule_Holiday}`,
        attribute: `${FleetSvcPolicyAttributeId.Sleep_Schedule}.holiday`,
        type: SubFeatureControlTypeEnum.Table,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'sleep_Schedule_Holiday',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Sleep_Schedule_Holiday}`,
        resource: `${ConstraintsResourceEnum.SleepConfiguration}`,
        settingsName: `${ConstraintsSettingsNameEnum.Sleep_Schedule_Holiday}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.SleepConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.CdmData}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.Asset_Number}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Asset_Number}`,
        attribute: `${FleetSvcPolicyAttributeId.Asset_Number}.value`,
        type: `${SubFeatureControlTypeEnum.Textbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'label',
        hint: 'hint',
        help: 'help',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Asset_Number}`,
        resource: `${ConstraintsResourceEnum.Device_Configuration}`,
        settingsName: `${ConstraintsSettingsNameEnum.Asset_Number}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.SystemResourceConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.AssetNumber}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.Company_Name}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Company_Name}`,
        attribute: `${FleetSvcPolicyAttributeId.Company_Name}.value`,
        type: `${SubFeatureControlTypeEnum.Textbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'label',
        hint: 'hint',
        help: 'help',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Company_Name}`,
        resource: `${ConstraintsResourceEnum.Device_Configuration}`,
        settingsName: `${ConstraintsSettingsNameEnum.Company_Name}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.SystemResourceConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.CompanyName}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.Contact_Person}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Contact_Person}`,
        attribute: `${FleetSvcPolicyAttributeId.Contact_Person}.value`,
        type: `${SubFeatureControlTypeEnum.Textbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'label',
        hint: 'hint',
        help: 'help',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Contact_Person}`,
        resource: `${ConstraintsResourceEnum.Device_Configuration}`,
        settingsName: `${ConstraintsSettingsNameEnum.Contact_Person}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.SystemResourceConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.CompanyContact}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.Device_Location}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Device_Location}`,
        attribute: `${FleetSvcPolicyAttributeId.Device_Location}.value`,
        type: `${SubFeatureControlTypeEnum.Textbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'label',
        hint: 'hint',
        help: 'help',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Device_Location}`,
        resource: `${ConstraintsResourceEnum.Device_Configuration}`,
        settingsName: `${ConstraintsSettingsNameEnum.Device_Location}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.SystemResourceConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.DeviceLocation}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.Device_Name}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Device_Name}`,
        attribute: `${FleetSvcPolicyAttributeId.Device_Name}.value`,
        type: `${SubFeatureControlTypeEnum.Textbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'label',
        hint: 'hint',
        help: 'help',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Device_Name}`,
        resource: `${ConstraintsResourceEnum.Device_Configuration}`,
        settingsName: `${ConstraintsSettingsNameEnum.Device_Name}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.SystemResourceConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.DeviceDescription}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.Duplex_Binding}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Duplex_Binding}`,
        attribute: `${FleetSvcPolicyAttributeId.Duplex_Binding}.mode`,
        type: `${SubFeatureControlTypeEnum.Dropbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'label',
        info: 'info',
        hint: 'hint',
        help: 'help',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Duplex_Binding}`,
        resource: `${ConstraintsResourceEnum.JobTicketConfigDefaults_Print}`,
        settingsName: `${ConstraintsSettingsNameEnum.Duplex_Binding}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.PrintConfigurationDefault}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.PrintJobDuplexBinding}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.Sleep_Settings}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Sleep_Settings_Sleep_Mode_Auto_Off_Timer}`,
        attribute: `${FleetSvcPolicyAttributeId.Sleep_Settings}.check`,
        type: `${SubFeatureControlTypeEnum.Checkbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'sleep_Settings_Sleep_Mode_Auto_Off_Timer_Label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Sleep_Settings_Sleep_Mode_Auto_Off_Timer}`,
        resource: `${ConstraintsResourceEnum.SleepConfiguration}`,
        settingsName: `${ConstraintsSettingsNameEnum.Sleep_Settings_Sleep_Mode_Auto_Off_Timer}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.SleepConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Sleep_Settings_Sleep_Mode_Auto_Off_Timer}`,
      },
      {
        id: `${SubfeatureIdEnum.Sleep_Settings_Sleep_Mode}`,
        attribute: `${FleetSvcPolicyAttributeId.Sleep_Settings}.value`,
        type: `${SubFeatureControlTypeEnum.Textbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'sleep_Settings_Sleep_Mode_Label',
        hint: 'hint',
        help: 'help',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Sleep_Settings_Sleep_Mode}`,
        resource: `${ConstraintsResourceEnum.SleepConfiguration}`,
        settingsName: `${ConstraintsSettingsNameEnum.Sleep_Settings_Sleep_Mode}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.SleepConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Sleep_Settings_Sleep_Mode}`,
      },
      {
        id: `${SubfeatureIdEnum.Sleep_Settings_Auto_On_Events}`,
        attribute: `${FleetSvcPolicyAttributeId.Sleep_Settings}.on-events`,
        type: `${SubFeatureControlTypeEnum.Radio}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'sleep_Settings_Auto_On_Events_Label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Sleep_Settings_Auto_On_Events}`,
        resource: `${ConstraintsResourceEnum.SleepConfiguration}`,
        settingsName: `${ConstraintsSettingsNameEnum.Sleep_Settings_Auto_On_Events}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.SleepConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Sleep_Settings_Auto_On_Events}`,
      },
      {
        id: `${SubfeatureIdEnum.Sleep_Settings_Auto_Off_After_Sleep}`,
        attribute: `${FleetSvcPolicyAttributeId.Sleep_Settings}.off-value`,
        type: `${SubFeatureControlTypeEnum.Textbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'sleep_Settings_Auto_Off_After_Sleep_Label',
        hint: 'hint',
        help: 'help',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Sleep_Settings_Auto_Off_After_Sleep}`,
        resource: `${ConstraintsResourceEnum.SleepConfiguration}`,
        settingsName: `${ConstraintsSettingsNameEnum.Sleep_Settings_Auto_Off_After_Sleep}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.SleepConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Sleep_Settings_Auto_Off_After_Sleep}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.Outgoing_Servers}`,
    policyCheck: true,
    customizable: `${SubFeatureCustomModeEnum.WRITEVIEW}`,
    subfeatures: [
      // {
      //   id: `${SubfeatureIdEnum.Outgoing_Server_List}`,
      //   accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
      //   label: 'serverList_label',
      //   subFeatureId: `${ConstraintsSubFeatureIdEnum.Outgoing_Servers_ServerName}`,
      //   resource: `${ConstraintsResourceEnum.Email_SmtpServers}`,
      //   settingsName: `${ConstraintsSettingsNameEnum.Outgoing_Servers_ServerName}`,
      //   isFmsPayload: false,
      // },
      {
        id: `${SubfeatureIdEnum.Outgoing_Server_List_OverwriteOption}`,
        attribute: `${FleetSvcPolicyAttributeId.Outgoing_Server}.mode`,
        type: `${SubFeatureControlTypeEnum.Radio}`,
        accessMode: `${SubFeatureAccessModeEnum.WRITEONLY}`,
        label: 'overwriteOption_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Outgoing_Servers_ServerName}`,
        resource: `${ConstraintsResourceEnum.Email_SmtpServers}`,
        settingsName: `${ConstraintsSettingsNameEnum.Outgoing_Servers_OverwriteOption}`,
      },
      {
        id: `${SubfeatureIdEnum.Outgoing_Server_List_OverWriteExistingName}`,
        attribute: `${FleetSvcPolicyAttributeId.Outgoing_Server}.overwrite-existing`,
        type: `${SubFeatureControlTypeEnum.Checkbox}`,
        accessMode: `${SubFeatureAccessModeEnum.WRITEONLY}`,
        indent: SubfeatureIndentEnum.Indent_Level_1,
        label: 'overwriteExistName_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Outgoing_Servers_ServerName}`,
        resource: `${ConstraintsResourceEnum.Email_SmtpServers}`,
        settingsName: `${ConstraintsSettingsNameEnum.Outgoing_Servers_OverWriteExistingName}`,
      },
      {
        id: `${SubfeatureIdEnum.Outgoing_Server_List_Email}`,
        attribute: `${FleetSvcPolicyAttributeId.Outgoing_Server}.servers`,
        type: `${SubFeatureControlTypeEnum.Table}`,
        accessMode: `${SubFeatureAccessModeEnum.READWRITE}`,
        label: 'email_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Outgoing_Servers_Email}`,
        resource: `${ConstraintsResourceEnum.Email_SmtpServers}`,
        settingsName: [`${ConstraintsSettingsNameEnum.Outgoing_Servers_Email}`,  `${ConstraintsSettingsNameEnum.Outgoing_Servers_InternetFax}`, `${ConstraintsSettingsNameEnum.Outgoing_Servers_AutomatedEmail}`, `${ConstraintsSettingsNameEnum.Outgoing_Servers_AutoSend}`,],
        serviceGunData: `${DeviceCacheServiceGunEnum.OutgoingServer}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.MockValueOutgoingServer}`,
        customizable: `${SubFeatureCustomModeEnum.WRITEVIEW}`,
        required: false,
      },
      {
        id: `${SubfeatureIdEnum.Outgoing_Server_Name}`,
        attribute: `${FleetSvcPolicyAttributeId.Outgoing_Server_Name}`,
        type: `${SubFeatureControlTypeEnum.Textbox}`,
        accessMode: `${SubFeatureAccessModeEnum.WRITEONLY}`,
        label: 'server_name_address',
        placeholder: 'serverName_placeHolder',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Outgoing_Servers_Name}`,
        resource: `${ConstraintsResourceEnum.Email_SmtpServers}`,
        settingsName: `${ConstraintsSettingsNameEnum.Outgoing_Servers_ServerName}`,
        isFmsPayload: false,
      },
      {
        id: `${SubfeatureIdEnum.Outgoing_Server_Port}`,
        attribute: `${FleetSvcPolicyAttributeId.Outgoing_Server_Port}`,
        type: `${SubFeatureControlTypeEnum.Textbox}`,
        accessMode: `${SubFeatureAccessModeEnum.WRITEONLY}`,
        label: 'server_port',
        placeholder: 'serverPort_placeHolder',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Outgoing_Servers_Port}`,
        resource: `${ConstraintsResourceEnum.Email_SmtpServers}`,
        settingsName: `${ConstraintsSettingsNameEnum.Outgoing_Servers_Port}`,
        isFmsPayload: false,
      },
      {
        id: `${SubfeatureIdEnum.Outgoing_Server_Split}`,
        attribute: `${FleetSvcPolicyAttributeId.Outgoing_Server_Split}`,
        type: `${SubFeatureControlTypeEnum.Dropbox}`,
        accessMode: `${SubFeatureAccessModeEnum.WRITEONLY}`,
        label: 'serverSplit_email',
        placeholder: 'serverSplit_placeHolder',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Outgoing_Servers_Email_Split}`,
        resource: `${ConstraintsResourceEnum.Email_SmtpServers}`,
        settingsName: `${ConstraintsSettingsNameEnum.Outgoing_Server_SplitEmailOption}`,
        isFmsPayload: false,
        customizable: `${SubFeatureCustomModeEnum.WRITEVIEW}`,
      },
      // {
      //   id: `${SubfeatureIdEnum.Outgoing_server_SplitEmailFileSize}`,
      //   type: `${SubFeatureControlTypeEnum.Textbox}`,
      //   hint: 'splitEmailFileSize_hint',
      //   accessMode: `${SubFeatureAccessModeEnum.WRITEONLY}`,
      //   indent: SubfeatureIndentEnum.Indent_Level_1,
      //   subFeatureId: `${ConstraintsSubFeatureIdEnum.Outgoing_Servers_Email_Split}`,
      //   resource: `${ConstraintsResourceEnum.Email_SmtpServers}`,
      //   settingsName: `${ConstraintsSettingsNameEnum.Outgoing_Server_SplitEmailOptionCustom}`,
      //   isFmsPayload: false,
      // },
      {
        id: `${SubfeatureIdEnum.Outgoing_Server_SMTP}`,
        attribute: `${FleetSvcPolicyAttributeId.Outgoing_Server_SMTP}`,
        type: `${SubFeatureControlTypeEnum.Checkbox}`,
        accessMode: `${SubFeatureAccessModeEnum.WRITEONLY}`,
        label: 'server_useSSL',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Outgoing_Servers_Ssl}`,
        resource: `${ConstraintsResourceEnum.Email_SmtpServers}`,
        settingsName: `${ConstraintsSettingsNameEnum.Outgoing_Servers_UseSsl}`,
        isFmsPayload: false,
      },
      {
        id: `${SubfeatureIdEnum.Outgoing_Server_Valid_Cert}`,
        attribute: `${FleetSvcPolicyAttributeId.Outgoing_Server_Valid_Cert}`,
        type: `${SubFeatureControlTypeEnum.Checkbox}`,
        accessMode: `${SubFeatureAccessModeEnum.WRITEONLY}`,
        indent: SubfeatureIndentEnum.Indent_Level_1,
        label: 'server_validCert',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Outgoing_Servers_Valid_Cert}`,
        resource: `${ConstraintsResourceEnum.Email_SmtpServers}`,
        settingsName: `${ConstraintsSettingsNameEnum.Outgoing_Servers_ValidCert}`,
        isFmsPayload: false,
      },
      {
        id: `${SubfeatureIdEnum.Outgoing_Server_Req_Auth}`,
        attribute: `${FleetSvcPolicyAttributeId.Outgoing_Server_Req_Auth}`,
        type: `${SubFeatureControlTypeEnum.Checkbox}`,
        accessMode: `${SubFeatureAccessModeEnum.WRITEONLY}`,
        label: 'server_reqAuth',
        heading: 'server_heading',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Outgoing_Servers_Req_Auth}`,
        resource: `${ConstraintsResourceEnum.Email_SmtpServers}`,
        settingsName: `${ConstraintsSettingsNameEnum.Outgoing_Servers_ReqAuth}`,
        isFmsPayload: false,
      },
      {
        id: `${SubfeatureIdEnum.Outgoing_Server_CredType}`,
        attribute: `${FleetSvcPolicyAttributeId.Outgoing_Server_CredType}`,
        type: `${SubFeatureControlTypeEnum.Radio}`,
        accessMode: `${SubFeatureAccessModeEnum.WRITEONLY}`,
        indent: SubfeatureIndentEnum.Indent_Level_1,
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Outgoing_Server_CredType}`,
        resource: `${ConstraintsResourceEnum.Email_SmtpServers}`,
        settingsName: `${ConstraintsSettingsNameEnum.Outgoing_Server_CredType}`,
        customizable: `${SubFeatureCustomModeEnum.WRITEVIEW}`,
        isFmsPayload: false,
      },
      {
        id: `${SubfeatureIdEnum.Outgoing_Server_User_Name}`,
        attribute: `${FleetSvcPolicyAttributeId.Outgoing_Server_User_Name}`,
        type: `${SubFeatureControlTypeEnum.Textbox}`,
        accessMode: `${SubFeatureAccessModeEnum.WRITEONLY}`,
        indent: SubfeatureIndentEnum.Indent_Level_1,
        label: 'server_userName',
        placeholder: 'userName_placeHolder',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Outgoing_Server_User_Name}`,
        resource: `${ConstraintsResourceEnum.Email_SmtpServers}`,
        settingsName: `${ConstraintsSettingsNameEnum.Outgoing_Server_User_Name}`,
        isFmsPayload: false,
      },
      {
        id: `${SubfeatureIdEnum.Outgoing_Server_Password}`,
        attribute: `${FleetSvcPolicyAttributeId.Outgoing_Server_Password}`,
        type: `${SubFeatureControlTypeEnum.Password}`,
        accessMode: `${SubFeatureAccessModeEnum.WRITEONLY}`,
        indent: SubfeatureIndentEnum.Indent_Level_1,
        label: 'server_password',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Outgoing_Server_Password}`,
        resource: `${ConstraintsResourceEnum.Email_SmtpServers}`,
        settingsName: `${ConstraintsSettingsNameEnum.Outgoing_Server_Password}`,
        isFmsPayload: false,
      },
      {
        id: `${SubfeatureIdEnum.Outgoing_Server_Send_Email}`,
        attribute: `${FleetSvcPolicyAttributeId.Outgoing_Server_Send_Email}`,
        type: `${SubFeatureControlTypeEnum.Checkbox}`,
        accessMode: `${SubFeatureAccessModeEnum.WRITEONLY}`,
        label: 'server_sendEmail',
        heading: 'server_usage',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Outgoing_Server_Send_Email}`,
        resource: `${ConstraintsResourceEnum.Email_SmtpServers}`,
        settingsName: `${ConstraintsSettingsNameEnum.Outgoing_Servers_Email}`,
        isFmsPayload: false,
      },
      {
        id: `${SubfeatureIdEnum.Outgoing_Server_Fax}`,
        attribute: `${FleetSvcPolicyAttributeId.Outgoing_Server_Fax}`,
        type: `${SubFeatureControlTypeEnum.Checkbox}`,
        accessMode: `${SubFeatureAccessModeEnum.WRITEONLY}`,
        label: 'internetFax_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Outgoing_Server_Fax}`,
        resource: `${ConstraintsResourceEnum.Email_SmtpServers}`,
        settingsName: `${ConstraintsSettingsNameEnum.Outgoing_Servers_InternetFax}`,
        isFmsPayload: false,
      },
      {
        id: `${SubfeatureIdEnum.Outgoing_Server_Auto_AutomatedEmail}`,
        attribute: `${FleetSvcPolicyAttributeId.Outgoing_Server_Auto_AutomatedEmail}`,
        type: `${SubFeatureControlTypeEnum.Checkbox}`,
        accessMode: `${SubFeatureAccessModeEnum.WRITEONLY}`,
        label: 'automatedEmail_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Outgoing_Server_Alert}`,
        resource: `${ConstraintsResourceEnum.Email_SmtpServers}`,
        settingsName: `${ConstraintsSettingsNameEnum.Outgoing_Servers_AutomatedEmail}`,
        isFmsPayload: false,
      },
      {
        id: `${SubfeatureIdEnum.Outgoing_Server_Auto_Send}`,
        attribute: `${FleetSvcPolicyAttributeId.Outgoing_Server_Auto_Send}`,
        type: `${SubFeatureControlTypeEnum.Checkbox}`,
        accessMode: `${SubFeatureAccessModeEnum.WRITEONLY}`,
        label: 'autoSend_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Outgoing_Server_Auto_Send}`,
        resource: `${ConstraintsResourceEnum.Email_SmtpServers}`,
        settingsName: `${ConstraintsSettingsNameEnum.Outgoing_Servers_AutoSend}`,
        isFmsPayload: false,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.TrayAdministration}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Tray_Admin}`,
        attribute: `${FleetSvcPolicyAttributeId.Tray_Admin}`,
        type: SubFeatureControlTypeEnum.Table,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Tray_Admin}`,
        resource: `${ConstraintsResourceEnum.Media_Configuration}`,
        settingsName: `${ConstraintsSettingsNameEnum.TrayAdmin_Inputs}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.TrayAdmin}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Tray_Admin}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.Home_Screen_customization_Futuresmart}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Home_Screen_Application}`,
        attribute: `${FleetSvcPolicyAttributeId.Home_Screen_Application}.app`,
        type: `${SubFeatureControlTypeEnum.HomeScreenFutureSmart}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'home_screen_application_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Home_Screen_Application}`,
        resource: `${ConstraintsResourceEnum.ControlPanel_Configuration}`,
        settingsName: `${ConstraintsSettingsNameEnum.Default_Home_Screen_Application}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.ControlPanelResource}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.DefaultHomeScreenApplication}`,
      },
      {
        id: `${SubfeatureIdEnum.Emergency_Client_Mode}`,
        attribute: `${FleetSvcPolicyAttributeId.Emergency_Client_Mode}.check`,
        type: `${SubFeatureControlTypeEnum.Toggle}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'emergency_client_mode_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Emergency_Client_Mode}`,
        resource: `${ConstraintsResourceEnum.ControlPanel_Configuration}`,
        settingsName: `${ConstraintsSettingsNameEnum.Emergency_Client_Mode}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.ControlPanelResource}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.EmergencyClientMode}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.Home_Screen_Language}`,
    configurable: false,
    visible : false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Home_Screen_Language_Enabled}`,
        attribute: `${FleetSvcPolicyAttributeId.Home_Screen_LanguageSelection}.check`,
        type: `${SubFeatureControlTypeEnum.Dropbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'home-screen-language-enabled_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Home_Screen_Language}`,
        resource: `${ConstraintsResourceEnum.HomeScreen_Language}`,
        settingsName: `${ConstraintsSettingsNameEnum.HomeScreen_Language}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.ControlPanelResource}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.HomeScreenLanguage}`,
      },
      {
        id: `${SubfeatureIdEnum.Home_Screen_AlternateKeyboard}`,
        attribute: `${FleetSvcPolicyAttributeId.Home_Screen_AlternateKeyboard}.alt`,
        type: `${SubFeatureControlTypeEnum.Dropbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'home-screen-alternate-keyboard_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Home_Screen_AlternateKeyboard}`,
        resource: `${ConstraintsResourceEnum.HomeScreen_Language}`,
        settingsName: `${ConstraintsSettingsNameEnum.HomeScreen_AlternateKeyboard}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.ControlPanelResource}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.HomeScreenAlternateKeyboard}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.Use_Requested_Tray}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Use_Requested_Tray}`,
        attribute: `${FleetSvcPolicyAttributeId.Use_Requested_Tray}.mode`,
        type: SubFeatureControlTypeEnum.Toggle,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Use_Requested_Tray}`,
        resource: `${ConstraintsResourceEnum.Media_Handling_Configuration}`,
        settingsName: `${ConstraintsSettingsNameEnum.Use_Requested_Tray}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.MediaHandling}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Use_Requested_Tray}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.Override_A4_Letter}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Override_A4_Letter}`,
        attribute: `${FleetSvcPolicyAttributeId.Override_A4_Letter}.check`,
        type: SubFeatureControlTypeEnum.Toggle,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Override_A4_Letter}`,
        resource: `${ConstraintsResourceEnum.Media_Handling_Configuration}`,
        settingsName: `${ConstraintsSettingsNameEnum.Override_A4_Letter}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.MediaHandling}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Override_A4_Letter}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.ManualFeedPrompt}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Manual_Feed_Prompt}`,
        attribute: `${FleetSvcPolicyAttributeId.ManualFeedPrompt}.mode`,
        type: `${SubFeatureControlTypeEnum.Radio}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'manualFeedPrompt_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Manual_Feed_Prompt}`,
        resource: `${ConstraintsResourceEnum.Media_Handling_Configuration}`,
        settingsName: `${ConstraintsSettingsNameEnum.ManualFeed_Prompt}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.MediaHandling}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.ManualFeedPrompt}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.Tray1_Mode_Manual_feed}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Tray1_Mode_Manual_feed}`,
        attribute: `${FleetSvcPolicyAttributeId.Tray1_Mode_Manual_feed}.mode`,
        type: `${SubFeatureControlTypeEnum.Radio}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Tray1_Mode_Manual_feed}`,
        resource: `${ConstraintsResourceEnum.Media_Handling_Configuration}`,
        settingsName: `${ConstraintsSettingsNameEnum.Tray1_Mode_Manual_feed}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.MediaHandling}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Tray1_Mode_Manual_feed}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.Size_Type_Prompt}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Size_Type_Prompt}`,
        attribute: `${FleetSvcPolicyAttributeId.Size_Type_Prompt}.mode`,
        type: `${SubFeatureControlTypeEnum.Checkbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'size_type_prompt_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Size_Type_Prompt}`,
        resource: `${ConstraintsResourceEnum.Media_Handling_Configuration}`,
        settingsName: `${ConstraintsSettingsNameEnum.Size_Type_Prompt}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.MediaHandling}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Size_Type_Prompt}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.Online_Solutions}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Online_Solutions_ShowEventQrCode}`,
        attribute: `${FleetSvcPolicyAttributeId.Online_Solutions}.show-qr-code`,
        type: `${SubFeatureControlTypeEnum.Checkbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'online-solutions-show-event-qr-code_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Online_Solutions_ShowEventQrCode}`,
        resource: `${ConstraintsResourceEnum.ControlPanel_Configuration}`,
        settingsName: `${ConstraintsSettingsNameEnum.Online_Solutions_ShowEventQrCode}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.ControlPanelResource}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.OnlineSolutionsShowEventQrCode}`,
      },
      {
        id: `${SubfeatureIdEnum.Online_Solutions_ShowSupportLinks}`,
        attribute: `${FleetSvcPolicyAttributeId.Online_Solutions}.show-links`,
        type: `${SubFeatureControlTypeEnum.Checkbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'online-solutions-show-support-links_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Online_Solutions_ShowSupportLinks}`,
        resource: `${ConstraintsResourceEnum.EWS_Configuration}`,
        settingsName: `${ConstraintsSettingsNameEnum.Online_Solutions_ShowSupportLinks}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.EwsConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.OnlineSolutionsShowSupportLinks}`,
      },
      {
        id: `${SubfeatureIdEnum.Online_Solutions_ShowLinksInEventLog}`,
        attribute: `${FleetSvcPolicyAttributeId.Online_Solutions}.admin-only`,
        type: `${SubFeatureControlTypeEnum.Checkbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'online-solutions-show-links-in-event-log_label',
        info: 'online-solutions-show-links-in-event-log_info',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Online_Solutions_ShowLinksInEventLog}`,
        resource: `${ConstraintsResourceEnum.EWS_Configuration}`,
        settingsName: `${ConstraintsSettingsNameEnum.Online_Solutions_ShowLinksInEventLog}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.EwsConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.OnlineSolutionsShowLinksInEventLog}`,
      },
    ],
  },
];
