import React, { Fragment } from 'react';
import { SubFeatureCustomModeEnum } from '../../../config/SubFeatureEnums';
import CustomSubFeatureSection from './CustomizableSection/CustomSubFeatureSection';
import { SubFeatureSection } from './Section/SubFeatureSection';

export const SubFeatureContainer = (props) => {
  const { deviceCacheResult, subFeatureData, isCustomizable , wex } = props;

  const groupedData = () => {
    const flatArray = subFeatureData
      .map((data) => {
        return Array.isArray(data.fleetValue) ? data.fleetValue : [];
      })
      .flat(1);

    const groupBy = flatArray.reduce(
      (map, e) => ({
        ...map,
        [e.group]: [...(map[e.group] ?? []), e],
      }),
      {},
    );

    return groupBy;
  };

  return (
    <Fragment>
      <>
        {isCustomizable && isCustomizable === SubFeatureCustomModeEnum.READVIEW ? (
          <CustomSubFeatureSection
            subFeatureData={subFeatureData}
            groupData={groupedData()}
            {...props}
          />
        ) : (
          subFeatureData &&
          subFeatureData.map((subFeature) => {
            return (
              <SubFeatureSection
                deviceCacheResult={deviceCacheResult}
                subFeature={subFeature}
                {...props}
                subFeatureData={subFeatureData}
              />
            );
          })
        )}
      </>
    </Fragment>
  );
};
