export const MockLaunchDarklyFeatures = [
  'fe-airprint-fax',
  'fe-airprint-scan',
  'fe-airprint-secure-scan',
  'fe-asset-number',
  'fe-bluetooth-low-energy',
  'fe-ca-certificate',
  'fe-cartridge-policy',
  'fe-company-name',
  'fe-contact-person',
  'fe-cors',
  'fe-ctrl-panel-language',
  'fe-date-time-format',
  'fe-default-media-size',
  'fe-default-media-type',
  'fe-delayverylow_messsage',
  'fe-device-location',
  'fe-device-name',
  'fe-dhcpv4-fqdn-compliance-with-rfc-4702',
  'fe-dot1x-authentication',
  'fe-dns-server',
  'fe-duplex-binding',
  'fe-emailAddress-messageSetting',
  'fe-ews-language',
  'fe-ews-password',
  'fe-ftp-printing',
  'fe-fuser-kit-threshold',
  'fe-hpjetadvantage-moreApps',
  'fe-id-certificate',
  'fe-ip-config-precedence',
  'fe-ipv4-information',
  'fe-ipv6-information',
  'fe-link-Settings',
  'fe-ldap-signin-setup',
  'fe-outgoing-servers',
  'fe-saveto-networkFolder',
  'fe-saveto-sharePoint',
  'fe-sent-email',
  'fe-service-access-code',
  'fe-sleep-delay',
  'fe-sleep-settings',
  'fe-smart-cloud-print',
  'fe-snmp-v1-v2',
  'fe-snmp-v3',
  'fe-time-service',
  'fe-time-zone',
  'fe-web-app-deployment',
  'fe-wifi-direct',
  'fe-tray-administration',
  'fe-color-copy-mode',
  'fe-hp-webservices',
  'fe-copy-job-build',
  'fe-toner-collection-unit',
  'fe-cartridge-verylowaction-color',
  'fe-cartridge-verylowaction-black',
  'fe-ews-config',
  'fe-cartridge-protection',
  'fe-home-screen-customization-futuresmart',
  'fe-access-control',
  'fe-cartridge-threshold-black',
  'fe-cartridge-threshold-cyan',
  'fe-cartridge-threshold-magenta',
  'fe-cartridge-threshold-yellow',
  'fe-drum-threshold-black',
  'fe-drum-threshold-cyan',
  'fe-drum-threshold-magenta',
  'fe-drum-threshold-yellow',
  'fe-homeScreen-Language',
  'fe-pjl-password',
  'fe-use-requested-tray',
  'fe-override-a4-letter',
  'fe-manual-feed-prompt',
  'fe-tray1-mode-manual-feed',
  'fe-system-location',
  'fe-system-contact',
  'fe-support-contact',
  'fe-size-type-prompt',
  'fe-proxy-server',
  'fe-bootloader-password',
  'fe-email-scan-settings',
  'fe-copy-background-cleanup',
  'fe-copy-contrast',
  'fe-copy-darkness',
  'fe-copy-optimize-text-picture',
  'fe-copy-paper-tray-selection',
  'fe-copy-sharpness',
  'fe-online-solutions',
  'fe-digital-sending-service'
];
export const MockLaunchDarklyAdvanceEntitlement = true;
