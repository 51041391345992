import React from 'react';
import useSystemLaunchers from '../../../../helper/SystemLaunchersHelper';
import LocalizeHelper from '../../../../helper/LocalizeHelper';
import { SubFeatureFleetDataStatus } from '../../../../config/SubFeatureEnums';
import ClampedDiv from './ClampedDiv';
import TableTemplate from './Table/TableTemplate';
import IPConfigPrecedenceCard from '../../SubFeature/CustomizableSection/IPConfigPrecedence/IPConfigPrecedenceCard';
import LowThreshold from '../../SubFeature/CustomizableSection/LowThreshold/LowThreshold';

export const useSectionFieldData = () => {
  const { getFleetValueStrings, getDeviceConfigurationsLocString, getCommonStrings } = LocalizeHelper();
  const { findLauncherValue } = useSystemLaunchers();
  const noDataAvailable = getCommonStrings('noDataAvailable');
  const { LowThresholdValue } = LowThreshold();

  const getFleetDataValue = (id, subId, value, result) => {
    if (value != null && value != 'undefined') {
      if (result == SubFeatureFleetDataStatus.PENDING) {
        return noDataAvailable;
      } else if (result == SubFeatureFleetDataStatus.FAILURE) {
        return noDataAvailable;
      } else {
        return <ClampedDiv children={value} id={id} subId={subId} />;
      }
    } else {
      return noDataAvailable;
    }
  };

  const getFleetDataOptions = (id, value, result, subId) => {
    if (value != null && value != 'undefined') {
      const valueToString = value?.toString();
      if (result == SubFeatureFleetDataStatus.PENDING) {
        return noDataAvailable;
      } else if (result == SubFeatureFleetDataStatus.FAILURE) {
        return noDataAvailable;
      } else if (valueToString) {
        return valueToString !== 'noSuchInstance' ? getDeviceConfigurationsLocString(id, valueToString, subId) : '--';
      } else {
        return noDataAvailable;
      }
    } else {
      return noDataAvailable;
    }
  };

  const getFleetDataDefaultValue = (value, result, key) => {
    if (value != null && value != 'undefined') {
      if (result == SubFeatureFleetDataStatus.PENDING) {
        return noDataAvailable;
      } else if (result == SubFeatureFleetDataStatus.FAILURE) {
        return noDataAvailable;
      } else {
        return getFleetValueStrings(key, value);
      }
    } else {
      return noDataAvailable;
    }
  };

  const getFleetDataTable = (id, value, result, subFeatureId, deviceConstraints, wex, deviceCacheResult) => {
    if (value != null && value != 'undefined') {
      if (result == SubFeatureFleetDataStatus.PENDING) {
        return noDataAvailable;
      } else if (result == SubFeatureFleetDataStatus.FAILURE) {
        return noDataAvailable;
      } else {
        return <TableTemplate id={id} subFeatureId={subFeatureId} value={value} wex={wex} deviceConstraints={deviceConstraints} deviceCacheResult={deviceCacheResult} />;
      }
    } else {
      return noDataAvailable;
    }
  };

  const getFleetDataIPconfigPrecedence = (id, value, result, subFeatureId) => {
    if (value != null && value != 'undefined') {
      if (result == SubFeatureFleetDataStatus.PENDING) {
        return noDataAvailable;
      } else if (result == SubFeatureFleetDataStatus.FAILURE) {
        return noDataAvailable;
      } else {
        return <IPConfigPrecedenceCard id={id} value={value} />;
      }
    } else {
      return noDataAvailable;
    }
  };

  const getDeviceCacheCartridgeThreshold = (id, value) => {
    if (value != null && value != 'undefined') {
      return LowThresholdValue(id, value);
    } else {
      return noDataAvailable;
    }
  };

  const getDeviceCacheHomeScreenFuturesmart = (id, value, result, subId) => {
    if (value != null && value != 'undefined') {
      if (result == SubFeatureFleetDataStatus.PENDING) {
        return noDataAvailable;
      } else if (result == SubFeatureFleetDataStatus.FAILURE) {
        return noDataAvailable;
      } else {
        const key = findLauncherValue(value);
        const resultValue = key ? getDeviceConfigurationsLocString(id, key, subId) : value;
        return resultValue;
      }
    } else {
      return noDataAvailable;
    }
  };

  return {
    getFleetDataValue,
    getFleetDataOptions,
    getFleetDataDefaultValue,
    getFleetDataTable,
    getFleetDataIPconfigPrecedence,
    getDeviceCacheCartridgeThreshold,
    getDeviceCacheHomeScreenFuturesmart,
  };
};
