import { IconHelp, IconApps } from '@veneer/core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { Fragment, useState } from 'react';
import { AppConfig } from '../../../../../src/config/App.config';
import LocalizeHelper from '../../../../helper/LocalizeHelper';
import { EditButton } from '../../../CommonExtended/EditButton/EditButton';
import InfoModal from '../../../CommonExtended/InfoModal/InfoModal';
import { EntitlementFeature } from '../../../EntitlementFeature/EntitlementFeature';
import { SubFeatureContainer } from '../../SubFeature/SubFeatureContainer';
import * as Style from '../Styles';
import { MockLaunchDarklyAdvanceEntitlement } from '../../../../../tests/mocks/launchDarkly';
import  LowThreshold  from '../../SubFeature/CustomizableSection/LowThreshold/LowThreshold';

export const FeatureSection = (props) => {
  const { deviceCacheResult, featuresData, handleOnEditClick, isWex} = props;
  const { getDeviceFeatureLocString, getDeviceFeatureModalString } = LocalizeHelper();
  const [showInfoModal, setShowInfoModal] = useState(false);
  const ldAdvancedEntitlement = props.isWex ? MockLaunchDarklyAdvanceEntitlement : useFlags()?.dcAdvancedEntitlement;
  const { LowThresholdHelper } = LowThreshold();

  const isEditButtonDisable = featuresData.id === 'fe-web-app-deployment' && featuresData.subfeatures?.some(
    (subfeature) => subfeature.id === 'app-installation' && Array.isArray(subfeature.fleetValue) && subfeature.fleetValue.length === 0 || subfeature.fleetValue === "false"
  ) || false;

  const handleInfoModal = () => {
    setShowInfoModal(!showInfoModal);
  };

  return (
    <Fragment>
      { LowThresholdHelper(featuresData.id, featuresData?.subfeatures[0]?.fleetValue) ? (
      <Style.FeatureListWrap>
        <Style.FeatureList>
          <Style.FeatureListItem>
            <Style.FeatureAccordion
              items={[
                {
                  id: `${featuresData.id}-body`,
                  class: 'feature-accordion-body',
                  expanded: false,
                  header: {
                    centralArea: (
                      <Style.AccordionHeader>
                        <Style.AccordionTitle
                          data-testid={'id-single-mode-feature-accordian-title'}
                        >
                          {getDeviceFeatureLocString(featuresData.id, 'name')}
                        </Style.AccordionTitle>
                        <Style.InfoButton
                          appearance="secondary"
                          leadingIcon={<IconHelp color="colorHpBlue7" size={20} />}
                          onClick={() => {
                            handleInfoModal();
                          }}
                          id={`${featuresData.id}-help-icon`}
                          isWex = {isWex}
                        />

                        {AppConfig.entitlementFeature.featureFlag &&
                        featuresData.entitlementId &&
                        ldAdvancedEntitlement ? (
                          <EntitlementFeature
                            entitlementId={ldAdvancedEntitlement}
                            styling={'readView'}
                          />
                        ) : null}
                        <InfoModal
                          show={showInfoModal}
                          handleClose={handleInfoModal}
                          title={getDeviceFeatureLocString(featuresData.id, 'name')}
                          content={getDeviceFeatureModalString(featuresData.id, 'description')}
                          id={`${featuresData.id}`}
                        />
                      </Style.AccordionHeader>
                    ),
                    endArea:
                      featuresData.configurable === undefined || featuresData.configurable ? (
                        <EditButton
                          onClick={() => {
                            handleOnEditClick(featuresData);
                          }}
                          data-testid={'id-single-mode-feature-edit-button'}
                          isWex = {isWex}
                          enable={isEditButtonDisable}
                        />
                      ) : null,
                  },
                  content: (
                    <SubFeatureContainer
                      deviceCacheResult={deviceCacheResult}
                      subFeatureData={featuresData.subfeatures}
                      isCustomizable={featuresData.customizable}
                      featureId={featuresData.id}
                      wex = {isWex}
                    />
                  ),
                },
              ]}
              behavior={'singleExpand'}
              className={'feature-accordion'}
              id={featuresData.id}
            />
          </Style.FeatureListItem>
        </Style.FeatureList>
      </Style.FeatureListWrap>
    ) : null}
      {/* {showModal ? (
        <EditView showModal={showModal} setShowModal={setShowModal} featuresData={featuresData} />
      ) : null} */}
    </Fragment>
  );
};
