import { Table } from '@veneer/core';
import React, { useEffect } from 'react';
import LocalizeHelper from '../../../../helper/LocalizeHelper';
import { InputLabel } from '../../../CommonExtended/InputTitle/InputLabel';
import * as Style from '../Styles';
import { StyledTable } from '../../../../../src/components/ReadView/ControlTemplates/SectionField/Styles';

const LdapBindSearchRootTable = (props) => {
  const { id, tableLabel, tableDatas, setSelectedCount, setSelectedData, isChecked, isWex} = props;
  const [tableData, setTableData] = React.useState([]);
  const { getDeviceSettingsLocString } = LocalizeHelper();

  function selectRow(dataArray, checked, id) {
    const updatedDataArray = [...dataArray];
    const index = updatedDataArray.findIndex((row) => row.uid === id);
    updatedDataArray[index].rowConfig.selected = checked;
    return updatedDataArray;
  }

  function selectAllPageItems(dataArray, checked) {
    return dataArray.map((row) => {
      const newRow = row;
      newRow.rowConfig.selected = checked;
      return newRow;
    });
  }

  function selectionState(dataArray) {
    const total = dataArray?.length;
    const selected = dataArray?.filter((row) => row.rowConfig.selected).length;

    switch (selected) {
      case 0:
        return 'none';
      case total:
        return 'all';
      default:
        return 'indeterminated';
    }
  }

  const handleSelect = (event, index) => {
    setTableData(selectRow(tableData, event.target.checked, index));
    const numberOfSelectedItems = tableData.filter((row) => row.rowConfig.selected);
    setSelectedData(numberOfSelectedItems);
  };

  const handleSelectAll = (event) => {
    setTableData(selectAllPageItems(tableData, event.target.checked));
    const numberOfSelectedItems = tableData.filter((row) => row.rowConfig.selected);
    setSelectedData(numberOfSelectedItems);
  };

  const title = getDeviceSettingsLocString(id, tableLabel);

  const numberOfSelectedItemsLen = tableData?.filter((row) => row.rowConfig.selected).length;

  setSelectedCount(numberOfSelectedItemsLen);

  useEffect(() => {
    setTableData(tableDatas);
  }, [tableDatas]);

  return (
    <>
      <InputLabel title={title} />
      <Style.TableWrapper isWex={isWex}>
        <StyledTable
          columns={[
            {
              id: 'root_name',
              label: 'Root Name',
            },
            {
              id: 'uid',
              label: 'Id',
              index: 'hidden',
            },
          ]}
          data={tableData}
          onSelect={handleSelect}
          onSelectAllPageItems={handleSelectAll}
          rowSelector={!isChecked ? 'multiSelection' : undefined}
          rowSelectAllState={selectionState(tableData)}
        />
      </Style.TableWrapper>
    </>
  );
};

export default LdapBindSearchRootTable;
