import { configureStore } from '@reduxjs/toolkit';
import { ThemeProvider, ToastProvider, useToast } from '@veneer/core';
import React, { useMemo } from 'react';
import { Provider } from 'react-redux';
import packageInfo from '../package.json';
import { RootComponent } from '../src';
import resources from '../src/assets/locale';
import { ShellPropsProvider } from '../src/shellProps';
import rootReducer from '../src/store';
import { ShellProps } from './types/shell';

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
});

declare global {
  interface Window {
    Shell: ShellProps;
  }
}

function EcpComponent(props) {
  const { v1 } = window.Shell as ShellProps;
  const namespace = '@jarvis/react-ecp-device-details-configuration';
  const themeProviderProps = v1?.theme?.getThemeProviderProperties?.();
  let makeAndModel: any[] = [];

  const { localization, containerId } = props;

  const TranslatorProvider = useMemo(
    () => localization.getReactTranslatorProvider(React),
    [localization],
  );

  if (!props.showMultipleConfigUi) {
    makeAndModel.push({ number: props.deviceModelNumber });
  } else {
    makeAndModel = props.selectedData.map((x) => {
      return x.identity.makeAndModel;
    });
  }

  return (
    <section id={namespace}>
      <TranslatorProvider resources={resources}>
        <ThemeProvider {...themeProviderProps}>
          <ToastProvider>
            <ShellPropsProvider
              id={packageInfo.name}
              ecpDeviceV55={sessionStorage.getItem('ecpDeviceV55') ?? false}
              ecpDeviceRolesPermissions={false}
              uxFramework={true}
              stack={props.stack}
              useToast={useToast}
              authProvider={v1.authProvider}
              showMultipleConfigUi={props.showMultipleConfigUi}
              selectedDeviceIdList={props.selectedDeviceIdList}
              handleUseToast={props.handleUseToast}
              showModal={props.showModal}
              setShowModal={props.setShowModal}
              localization={localization}
              containerId={containerId}
            >
              <ToastProvider>
                <Provider store={store}>
                  <RootComponent {...props} makeAndModel={makeAndModel} />
                </Provider>
              </ToastProvider>
            </ShellPropsProvider>
          </ToastProvider>
        </ThemeProvider>
      </TranslatorProvider>
    </section>
  );
}

export default EcpComponent;
