import {
  ConstraintsResourceEnum,
  ConstraintsSettingsNameEnum,
  ConstraintsSubFeatureIdEnum,
} from '../config/ConstraintsEnums';
import { DeviceCacheCdmDataEnum } from '../config/DeviceCacheCdmDataEnums';
import { DeviceCacheServiceGunEnum } from '../config/DeviceCacheServiceGunEnums';
import { FeatureEntitlementEnum, FeatureIdEnum } from '../config/FeatureEnums';
import { FleetSvcPolicyAttributeId } from '../config/FleetServiceEnums';

import {
  SubFeatureAccessModeEnum,
  SubFeatureControlTypeEnum,
  SubFeatureCustomModeEnum,
  SubfeatureIdEnum,
  SubfeatureIndentEnum,
} from '../config/SubFeatureEnums';

export const SecurityDataModel = [
  {
    id: `${FeatureIdEnum.StoredDataPin_Protection}`,
    configurable: false,
    subfeatures: [
      // {
      //   id: `${SubfeatureIdEnum.Stored_Data_Pin_Protection_pinLimit}`,
      //   attribute: `${FleetSvcPolicyAttributeId.StoredData_PinLimit}.four-digits`,
      //   type: `${SubFeatureControlTypeEnum.Toggle}`,
      //   accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
      //   label: 'pinLimit',
      //   subFeatureId: `${ConstraintsSubFeatureIdEnum.Stored_Data_Pin_Protection_Limit}`,
      //   resource: `${ConstraintsResourceEnum.StoredDataPin_Protection}`,
      //   settingsName: `${ConstraintsSettingsNameEnum.Stored_Data_Pin_Protection_RequireScanJobStorage}`,
      //   serviceGunData: `${DeviceCacheServiceGunEnum.StoredDataPinProtection}`,
      //   serviceSettingsData: `${DeviceCacheCdmDataEnum.StoredDataPinProtection_RequireScanJobStorage}`,
      // },
      {
        id: `${SubfeatureIdEnum.Stored_Data_Pin_Protection_RequirePrintJobPinProtection}`,
        attribute: `${FleetSvcPolicyAttributeId.StoredData_PinLimit}.scan-job`,
        type: `${SubFeatureControlTypeEnum.Toggle}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'requirePrintJobPinProtection',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Stored_Data_Pin_Protection_RequireScanJobStorage}`,
        resource: `${ConstraintsResourceEnum.StoredDataPin_Protection}`,
        settingsName: `${ConstraintsSettingsNameEnum.Stored_Data_Pin_Protection_RequireScanJobStorage}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.StoredDataPinProtection}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.StoredDataPinProtection_RequireScanJobStorage}`,
      },
    //  {
    //     id: `${SubfeatureIdEnum.Stored_Data_Pin_Protection_PrintdriverStoredjobs}`,
    //     attribute: `${FleetSvcPolicyAttributeId.StoredData_PinLimit}.print-job`,
    //     type: `${SubFeatureControlTypeEnum.Toggle}`,
    //     accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
    //     label: 'printdriverstoredjobs',
    //     subFeatureId: `${ConstraintsSubFeatureIdEnum.Stored_Data_Pin_Protection_Printdriverstoredjobs}`,
    //     resource: `${ConstraintsResourceEnum.StoredDataPin_Protection}`,
    //     settingsName: `${ConstraintsSettingsNameEnum.Stored_Data_Pin_Protection_RequireScanJobStorage}`,
    //     serviceGunData: `${DeviceCacheServiceGunEnum.StoredDataPinProtection}`,
    //     serviceSettingsData: `${DeviceCacheCdmDataEnum.StoredDataPinProtection_RequireScanJobStorage}`,
    //   },
      // {
      //   id: `${SubfeatureIdEnum.Stored_Data_Pin_Protection_CancelStoredJob}`,
      //   attribute: `${FleetSvcPolicyAttributeId.StoredData_PinLimit}.allow-cancel`,
      //   type: `${SubFeatureControlTypeEnum.Toggle}`,
      //   accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
      //   label: 'cancelStoredJob',
      //   subFeatureId: `${ConstraintsSubFeatureIdEnum.Stored_Data_Pin_Protection_CancelStoredJob}`,
      //   resource: `${ConstraintsResourceEnum.StoredDataPin_Protection}`,
      //   settingsName: `${ConstraintsSettingsNameEnum.Stored_Data_Pin_Protection_RequireScanJobStorage}`,
      //   serviceGunData: `${DeviceCacheServiceGunEnum.StoredDataPinProtection}`,
      //   serviceSettingsData: `${DeviceCacheCdmDataEnum.StoredDataPinProtection_RequireScanJobStorage}`,
      // },
    ],
  },
  {
    id: `${FeatureIdEnum.Restrict_Color}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Restrict_Color}`,
        attribute: `${FleetSvcPolicyAttributeId.Restrict_Color}.mode`,
        type: `${SubFeatureControlTypeEnum.Dropbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'restrict-Color_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Restrict_Color}`,
        resource: `${ConstraintsResourceEnum.FleetProxyLegacyAttributes}`,
        settingsName: `${ConstraintsSettingsNameEnum.Restrict_Color}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.FleetProxyLegacyAttributes}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Restrict_Color_Enable}`,
      },
      {
        id: `${SubfeatureIdEnum.Restrict_User_Permissions}`,
        attribute: `${FleetSvcPolicyAttributeId.Restrict_Color}.user`,
        type: `${SubFeatureControlTypeEnum.Dropbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'Restrict by User Permissions_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Restrict_User_Permissions}`,
        resource: `${ConstraintsResourceEnum.FleetProxyLegacyAttributes}`,
        settingsName: `${ConstraintsSettingsNameEnum.Restrict_User_Permissions}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.FleetProxyLegacyAttributes}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Restrict_User_Permissions}`,
      },
      {
        id: `${SubfeatureIdEnum.Restrict_Application}`,
        attribute: `${FleetSvcPolicyAttributeId.Restrict_Color}.app`,
        type: `${SubFeatureControlTypeEnum.Dropbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'Restrict by Application_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Restrict_Application}`,
        resource: `${ConstraintsResourceEnum.FleetProxyLegacyAttributes}`,
        settingsName: `${ConstraintsSettingsNameEnum.Restrict_Application}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.FleetProxyLegacyAttributes}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Restrict_Application}`,
      },
      {
        id: `${SubfeatureIdEnum.Default_Permissions}`,
        attribute: `${FleetSvcPolicyAttributeId.Restrict_Color}.def-app-perm`,
        type: `${SubFeatureControlTypeEnum.Dropbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'Default Permissions_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Default_Permissions}`,
        resource: `${ConstraintsResourceEnum.FleetProxyLegacyAttributes}`,
        settingsName: `${ConstraintsSettingsNameEnum.Default_Permissions}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.FleetProxyLegacyAttributes}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Default_Permission}`,
      },
      {
        id: `${SubfeatureIdEnum.Non_Default_Applications}`,
        attribute: `${FleetSvcPolicyAttributeId.Restrict_Color}.app-perm`,
        type: `${SubFeatureControlTypeEnum.Table}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'Non Default Applications_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Non_Default_Applications}`,
        resource: `${ConstraintsResourceEnum.FleetProxyLegacyAttributes}`,
        settingsName: `${ConstraintsSettingsNameEnum.Non_Default_Applications}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.FleetProxyLegacyAttributes}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Non_Default_Applications}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.Dot1x_Authentication}`,
    configurable: true,
    visible: true,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Dot1x_Authentication_PeapEnabled}`,
        attribute: `${FleetSvcPolicyAttributeId.Dot1x_802_1x_auth}.peap`,
        type: `${SubFeatureControlTypeEnum.Checkbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READWRITE}`,
        label: 'dot1xauthentication_PeapEnabled_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Dot1x_Authentication_PeapEnabled}`,
        resource: `${ConstraintsResourceEnum.AapterConfigs}`,
        settingsName: `${ConstraintsSettingsNameEnum.Dot1x_Authentication_PeapEnabled}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.Eth0_DeviceAdapterConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Dot1x_Authentication_PeapEnabled}`,
        required: false,
      },
      {
        id: `${SubfeatureIdEnum.Dot1x_Authentication_TlsEnabled}`,
        attribute: `${FleetSvcPolicyAttributeId.Dot1x_802_1x_auth}.tls`,
        type: `${SubFeatureControlTypeEnum.Checkbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READWRITE}`,
        label: 'dot1xauthentication_TlsEnabled_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Dot1x_Authentication_TlsEnabled}`,
        resource: `${ConstraintsResourceEnum.AapterConfigs}`,
        settingsName: `${ConstraintsSettingsNameEnum.Dot1x_Authentication_TlsEnabled}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.Eth0_DeviceAdapterConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Dot1x_Authentication_TlsEnabled}`,
        required: false,
      },
      {
        id: `${SubfeatureIdEnum.Dot1x_Authentication_Username}`,
        attribute: `${FleetSvcPolicyAttributeId.Dot1x_802_1x_auth}.user`,
        type: `${SubFeatureControlTypeEnum.Textbox}`,
        accessMode: `${SubFeatureAccessModeEnum.WRITEONLY}`,
        label: 'dot1xauthentication_Username_label',
        placeholder: 'Username_hint',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Dot1x_Authentication_Username}`,
        resource: `${ConstraintsResourceEnum.AapterConfigs}`,
        settingsName: `${ConstraintsSettingsNameEnum.Dot1x_Authentication_Username}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.Eth0_DeviceAdapterConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Dot1x_Authentication_Username}`,
      },
      {
        id: `${SubfeatureIdEnum.Dot1x_Authentication_Password}`,
        attribute: `${FleetSvcPolicyAttributeId.Dot1x_802_1x_auth}.password`,
        type: `${SubFeatureControlTypeEnum.Password}`,
        accessMode: `${SubFeatureAccessModeEnum.WRITEONLY}`,
        confirmpassword: true,
        label: 'dot1xauthentication_Password_label',
        confirmPasswordLabel: 'dot1xauthentication_ConfirmPassword_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Dot1x_Authentication_Password}`,
        resource: `${ConstraintsResourceEnum.AapterConfigs}`,
        settingsName: `${ConstraintsSettingsNameEnum.Dot1x_Authentication_Password}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.Eth0_DeviceAdapterConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Dot1x_Authentication_Password}`,
      },
      {
        id: `${SubfeatureIdEnum.Dot1x_Authentication_ServerId}`,
        attribute: `${FleetSvcPolicyAttributeId.Dot1x_802_1x_auth}.server-id`,
        type: `${SubFeatureControlTypeEnum.Textbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READWRITE}`,
        label: 'dot1xauthentication_ServerId_label',
        placeholder: 'ServerId_hint',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Dot1x_Authentication_ServerId}`,
        resource: `${ConstraintsResourceEnum.AapterConfigs}`,
        settingsName: `${ConstraintsSettingsNameEnum.Dot1x_Authentication_ServerId}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.Eth0_DeviceAdapterConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Dot1x_Authentication_ServerId}`,
        required: false,
      },
      {
        id: `${SubfeatureIdEnum.Dot1x_Authentication_RequireExactMatch}`,
        attribute: `${FleetSvcPolicyAttributeId.Dot1x_802_1x_auth}.exact-match`,
        type: `${SubFeatureControlTypeEnum.Checkbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READWRITE}`,
        label: 'dot1xauthentication_RequireExactMatch_label',
        indent: SubfeatureIndentEnum.Indent_Level_1,
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Dot1x_Authentication_RequireExactMatch}`,
        resource: `${ConstraintsResourceEnum.AapterConfigs}`,
        settingsName: `${ConstraintsSettingsNameEnum.Dot1x_Authentication_RequireExactMatch}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.Eth0_DeviceAdapterConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Dot1x_Authentication_RequireExactMatch}`,
        required: false,
      },
      {
        id: `${SubfeatureIdEnum.Dot1x_Authentication_OnAuthenticationFailure}`,
        attribute: `${FleetSvcPolicyAttributeId.Dot1x_802_1x_auth}.on-failure`,
        type: `${SubFeatureControlTypeEnum.Radio}`,
        accessMode: `${SubFeatureAccessModeEnum.READWRITE}`,
        label: 'dot1xauthentication_OnAuthenticationFailure_label',
        info: 'dot1xauthentication_OnAuthenticationFailure_info',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Dot1x_Authentication_OnAuthenticationFailure}`,
        resource: `${ConstraintsResourceEnum.AapterConfigs}`,
        settingsName: `${ConstraintsSettingsNameEnum.Dot1x_Authentication_OnAuthenticationFailure}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.Eth0_DeviceAdapterConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Dot1x_Authentication_OnAuthenticationFailure}`,
        customizable: `${SubFeatureCustomModeEnum.WRITEVIEW}`,
      },
      {
        id: `${SubfeatureIdEnum.Dot1x_Authentication_Reauthenticate}`,
        attribute: `${FleetSvcPolicyAttributeId.Dot1x_802_1x_auth}.reauthenticate`,
        type: `${SubFeatureControlTypeEnum.Checkbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READWRITE}`,
        label: 'dot1xauthentication_Reauthenticate_label',
        info: 'dot1xauthentication_Reauthenticate_info',
        info1: 'dot1xauthentication_Reauthenticate_info1',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Dot1x_Authentication_Reauthenticate}`,
        resource: `${ConstraintsResourceEnum.AapterConfigs}`,
        settingsName: `${ConstraintsSettingsNameEnum.Dot1x_Authentication_Reauthenticate}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.Eth0_DeviceAdapterConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Dot1x_Authentication_Reauthenticate}`,
        required: false,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.Ews_Admin_Password}`,
    policyCheck: true,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Ews_Admin_Password_Check}`,
        attribute: `${FleetSvcPolicyAttributeId.Ews_Admin_Password}.check`,
        type: `${SubFeatureControlTypeEnum.Toggle}`,
        accessMode: `${SubFeatureAccessModeEnum.READWRITE_DISABLE}`,
        visible: false,
        label: 'passwordCheck_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.EWS_Admin_Password_Check}`,
        resource: `${ConstraintsResourceEnum.DeviceAdminConfig}`,
        settingsName: `${ConstraintsSettingsNameEnum.Configured_ByUser}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.DeviceAdminConfig}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.AdminConfigPasswordSet}`,
      },
      {
        id: `${SubfeatureIdEnum.Ews_Proposed_Password}`,
        attribute: `${FleetSvcPolicyAttributeId.Ews_Admin_Password}.value`,
        type: `${SubFeatureControlTypeEnum.Password}`,
        accessMode: `${SubFeatureAccessModeEnum.READWRITE}`,
        confirmpassword: true,
        label: 'password_label',
        confirmPasswordLabel: 'confirmpassword_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Ews_Password_Set}`,
        resource: `${ConstraintsResourceEnum.DeviceAdminConfig}`,
        settingsName: `${ConstraintsSettingsNameEnum.Credentials_ProposedPassword}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.DeviceAdminConfig}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.AdminConfigPasswordSet}`,
        customizable: `${SubFeatureCustomModeEnum.WRITEVIEW}`,
      },
      {
        id: `${SubfeatureIdEnum.Ews_Admin_MinimumPasswordLength}`,
        attribute: `${FleetSvcPolicyAttributeId.Ews_Admin_Password}.min-length`,
        type: `${SubFeatureControlTypeEnum.Textbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READWRITE_DISABLE}`,
        label: 'minPassLength_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Ews_Admin_MinimumPasswordLength}`,
        resource: `${ConstraintsResourceEnum.AccountPolicy}`,
        settingsName: `${ConstraintsSettingsNameEnum.Ews_Admin_MinimumPasswordLength}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.SecurityAccountPolicy}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.AccountPolicyMinPasswordLength}`,
      },
      {
        id: `${SubfeatureIdEnum.Ews_Admin_Password_Complexity}`,
        attribute: `${FleetSvcPolicyAttributeId.Ews_Admin_Password}.complexity`,
        type: `${SubFeatureControlTypeEnum.Checkbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READWRITE_DISABLE}`,
        label: 'passComplex_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Ews_Admin_PasswordComplexity}`,
        resource: `${ConstraintsResourceEnum.AccountPolicy}`,
        settingsName: `${ConstraintsSettingsNameEnum.Ews_Admin_PasswordComplexity}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.SecurityAccountPolicy}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.AccountPolicyPasswordComplexityEnabled}`,
      },
      {
        id: `${SubfeatureIdEnum.Ews_Admin_Password_AccountLockout}`,
        attribute: `${FleetSvcPolicyAttributeId.Ews_Admin_Password}.lockout`,
        type: `${SubFeatureControlTypeEnum.Checkbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READWRITE_DISABLE}`,
        label: 'passAccLockout_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Ews_Admin_PasswordAccountLockout}`,
        resource: `${ConstraintsResourceEnum.AccountPolicy}`,
        settingsName: `${ConstraintsSettingsNameEnum.Ews_Admin_PasswordAccountLockout}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.SecurityAccountPolicy}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.AccountPolicyAccountLockoutEnabled}`,
      },
      {
        id: `${SubfeatureIdEnum.Ews_Admin_Password_Maximum_Attempts}`,
        attribute: `${FleetSvcPolicyAttributeId.Ews_Admin_Password}.max-attempts`,
        type: `${SubFeatureControlTypeEnum.Textbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READWRITE_DISABLE}`,
        indent: SubfeatureIndentEnum.Indent_Level_1,
        label: 'passMaxAttempt_label',
        info: 'passMaxAttempt_info',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Ews_Admin_PasswordMaximumAttempts}`,
        resource: `${ConstraintsResourceEnum.AccountPolicy}`,
        settingsName: `${ConstraintsSettingsNameEnum.Ews_Admin_PasswordMaximumAttempts}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.SecurityAccountPolicy}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.AccountPolicyAccountLockoutMaximumAttempts}`,
      },
      {
        id: `${SubfeatureIdEnum.Ews_Admin_Password_ResetAfterSecs}`,
        attribute: `${FleetSvcPolicyAttributeId.Ews_Admin_Password}.reset-after`,
        type: `${SubFeatureControlTypeEnum.Textbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READWRITE_DISABLE}`,
        indent: SubfeatureIndentEnum.Indent_Level_1,
        label: 'passReset_label',
        info: 'passReset_info',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Ews_Admin_PasswordResetAfterSecs}`,
        resource: `${ConstraintsResourceEnum.AccountPolicy}`,
        settingsName: `${ConstraintsSettingsNameEnum.Ews_Admin_PasswordResetAfterSecs}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.SecurityAccountPolicy}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.AccountPolicyResetLockoutCounterInterval}`,
      },
      {
        id: `${SubfeatureIdEnum.Ews_Admin_Password_LockDurationSecs}`,
        attribute: `${FleetSvcPolicyAttributeId.Ews_Admin_Password}.lockout-duration`,
        type: `${SubFeatureControlTypeEnum.Textbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READWRITE_DISABLE}`,
        indent: SubfeatureIndentEnum.Indent_Level_1,
        label: 'passLockDuration_label',
        info: 'passLockDuration_info',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Ews_Admin_PasswordLockDurationSecs}`,
        resource: `${ConstraintsResourceEnum.AccountPolicy}`,
        settingsName: `${ConstraintsSettingsNameEnum.Ews_Admin_PasswordLockDurationSecs}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.SecurityAccountPolicy}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.AccountPolicyConfigLockoutInterval}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.CORS}`,
    configurable: false,
    visible: true,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.CORSEnabled}`,
        attribute: `${FleetSvcPolicyAttributeId.CORS}.check`,
        type: `${SubFeatureControlTypeEnum.Toggle}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'CORS_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.CORS_Enabled}`,
        resource: `${ConstraintsResourceEnum.CorsConfig}`,
        settingsName: `${ConstraintsSettingsNameEnum.CORS_Enabled}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.SystemConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.SystemconfigCORSEnabled}`,
      },
      {
        id: `${SubfeatureIdEnum.CORSTrustedSite}`,
        attribute: `${FleetSvcPolicyAttributeId.CORS}.sites`,
        type: `${SubFeatureControlTypeEnum.Table}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'TrutedSite_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.CORS_TrustedSites}`,
        resource: `${ConstraintsResourceEnum.CorsConfig}`,
        settingsName: `${ConstraintsSettingsNameEnum.CORS_TrustedSites}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.SystemConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.SystemconfigCORSTrustedSite}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.LDAP_SignIn_Setup}`,
    policyCheck: true,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.LDAP_SignIn}`,
        attribute: `${FleetSvcPolicyAttributeId.LDAP_Setup}.check`,
        type: `${SubFeatureControlTypeEnum.Checkbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READWRITE}`,
        label: 'check',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.LDAP_SignIn}`,
        resource: `${ConstraintsResourceEnum.LDAP_Configuration}`,
        settingsName: `${ConstraintsSettingsNameEnum.LDAP_SignIn}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.LDAPConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.LDAP_SignIn}`,
      },
      {
        id: `${SubfeatureIdEnum.LDAP_Server_Address}`,
        attribute: `${FleetSvcPolicyAttributeId.LDAP_Setup}.address`,
        type: `${SubFeatureControlTypeEnum.Textbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READWRITE}`,
        indent: SubfeatureIndentEnum.Indent_Level_1,
        label: 'address',
        placeholder: 'address_hint',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.LDAP_Server_Address}`,
        resource: `${ConstraintsResourceEnum.LDAP_Configuration}`,
        settingsName: `${ConstraintsSettingsNameEnum.LDAP_Server_Address}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.LDAPConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.LDAP_ServerAddress}`,
      },
      {
        id: `${SubfeatureIdEnum.LDAP_Port_Number}`,
        attribute: `${FleetSvcPolicyAttributeId.LDAP_Setup}.port`,
        type: `${SubFeatureControlTypeEnum.Textbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READWRITE}`,
        indent: SubfeatureIndentEnum.Indent_Level_1,
        label: 'port',
        placeholder: 'port_hint',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.LDAP_Port_Number}`,
        resource: `${ConstraintsResourceEnum.LDAP_Configuration}`,
        settingsName: `${ConstraintsSettingsNameEnum.LDAP_Port_Number}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.LDAPConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.LDAP_PortNumber}`,
      },
      {
        id: `${SubfeatureIdEnum.LDAP_Use_SSL}`,
        attribute: `${FleetSvcPolicyAttributeId.LDAP_Setup}.ssl`,
        type: `${SubFeatureControlTypeEnum.Checkbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READWRITE}`,
        indent: SubfeatureIndentEnum.Indent_Level_1,
        label: 'ssl',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.LDAP_Use_SSL}`,
        resource: `${ConstraintsResourceEnum.LDAP_Configuration}`,
        settingsName: `${ConstraintsSettingsNameEnum.LDAP_Use_SSL}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.LDAPConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.LDAP_UseSSL}`,
      },
      {
        id: `${SubfeatureIdEnum.LDAP_Server_Authentication}`,
        attribute: `${FleetSvcPolicyAttributeId.LDAP_Setup}.credential`,
        type: `${SubFeatureControlTypeEnum.Radio}`,
        accessMode: `${SubFeatureAccessModeEnum.READWRITE}`,
        indent: SubfeatureIndentEnum.Indent_Level_1,
        label: 'server_authentication',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.LDAP_Server_Authentication}`,
        resource: `${ConstraintsResourceEnum.LDAP_Configuration}`,
        settingsName: `${ConstraintsSettingsNameEnum.LDAP_Server_Authentication}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.LDAPConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.LDAP_Use_Admin_Credentials}`,
        customizable: `${SubFeatureCustomModeEnum.WRITEVIEW}`,
      },
      {
        id: `${SubfeatureIdEnum.LDAP_Bind_Prefix}`,
        attribute: `${FleetSvcPolicyAttributeId.LDAP_Setup}.bind-prefix`,
        type: `${SubFeatureControlTypeEnum.Textbox}`,
        accessMode: `${SubFeatureAccessModeEnum.WRITEONLY}`,
        indent: SubfeatureIndentEnum.Indent_Level_2,
        label: 'bind_prefix',
        placeholder: 'bind_prefix_hint',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.LDAP_Bind_Prefix}`,
        resource: `${ConstraintsResourceEnum.LDAP_Configuration}`,
        settingsName: `${ConstraintsSettingsNameEnum.LDAP_Bind_Prefix}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.LDAPConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.LDAP_Bind_Prefix}`,
        customizable: `${SubFeatureCustomModeEnum.WRITEVIEW}`,
      },
      {
        id: `${SubfeatureIdEnum.LDAP_Admin_DN}`,
        attribute: `${FleetSvcPolicyAttributeId.LDAP_Setup}.domain-name`,
        type: `${SubFeatureControlTypeEnum.Textbox}`,
        accessMode: `${SubFeatureAccessModeEnum.WRITEONLY}`,
        indent: SubfeatureIndentEnum.Indent_Level_2,
        label: 'distinguised_domain_name',
        placeholder: 'distinguised_domain_hint',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.LDAP_Admin_DN}`,
        resource: `${ConstraintsResourceEnum.LDAP_Configuration}`,
        settingsName: `${ConstraintsSettingsNameEnum.LDAP_Admin_DN}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.LDAPConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.LDAP_Admin_DN}`,
        customizable: `${SubFeatureCustomModeEnum.WRITEVIEW}`,
      },
      {
        id: `${SubfeatureIdEnum.LDAP_Admin_DN_Password}`,
        attribute: `${FleetSvcPolicyAttributeId.LDAP_Setup}.password`,
        type: `${SubFeatureControlTypeEnum.Password}`,
        accessMode: `${SubFeatureAccessModeEnum.WRITEONLY}`,
        indent: SubfeatureIndentEnum.Indent_Level_2,
        label: 'distinguised_domain_password',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.LDAP_Admin_Password}`,
        resource: `${ConstraintsResourceEnum.LDAP_Configuration}`,
        settingsName: `${ConstraintsSettingsNameEnum.LDAP_Admin_Password}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.LDAPConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.LDAP_Admin_Password}`,
        customizable: `${SubFeatureCustomModeEnum.WRITEVIEW}`,
      },
      {
        id: `${SubfeatureIdEnum.LDAP_Bind_And_Search_Root}`,
        attribute: `${FleetSvcPolicyAttributeId.LDAP_Setup}.roots`,
        type: `${SubFeatureControlTypeEnum.Table}`,
        accessMode: `${SubFeatureAccessModeEnum.READWRITE}`,
        indent: SubfeatureIndentEnum.Indent_Level_1,
        label: 'roots',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.LDAP_Bind_And_Search_Root}`,
        resource: `${ConstraintsResourceEnum.LDAP_Configuration}`,
        settingsName: `${ConstraintsSettingsNameEnum.LDAP_Bind_And_Search_Root}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.LDAPConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.LDAP_BindAndSearchRoot}`,
        customizable: `${SubFeatureCustomModeEnum.WRITEVIEW}`,
        required: false,
      },
      {
        id: `${SubfeatureIdEnum.LDAP_match_name_with_attribute}`,
        attribute: `${FleetSvcPolicyAttributeId.LDAP_Setup}.match-name`,
        type: `${SubFeatureControlTypeEnum.Textbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READWRITE}`,
        indent: SubfeatureIndentEnum.Indent_Level_1,
        label: 'match-name',
        placeholder: 'name_hint',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.LDAP_match_name_with_attribute}`,
        resource: `${ConstraintsResourceEnum.LDAP_Configuration}`,
        settingsName: `${ConstraintsSettingsNameEnum.LDAP_match_name_with_attribute}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.LDAPConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.LDAP_MatchNameWithAttribute}`,
      },
      {
        id: `${SubfeatureIdEnum.LDAP_retrieve_user_email}`,
        attribute: `${FleetSvcPolicyAttributeId.LDAP_Setup}.retrieve-email`,
        type: `${SubFeatureControlTypeEnum.Textbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READWRITE}`,
        indent: SubfeatureIndentEnum.Indent_Level_1,
        label: 'retrieve-email',
        placeholder: 'name_hint',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.LDAP_retrieve_user_email}`,
        resource: `${ConstraintsResourceEnum.LDAP_Configuration}`,
        settingsName: `${ConstraintsSettingsNameEnum.LDAP_retrieve_user_email}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.LDAPConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.LDAP_RetrieveUserEmail}`,
      },
      {
        id: `${SubfeatureIdEnum.LDAP_retrieve_user_name}`,
        attribute: `${FleetSvcPolicyAttributeId.LDAP_Setup}.retrieve-name`,
        type: `${SubFeatureControlTypeEnum.Textbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READWRITE}`,
        indent: SubfeatureIndentEnum.Indent_Level_1,
        label: 'retrieve-name',
        placeholder: 'name_hint',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.LDAP_retrieve_user_name}`,
        resource: `${ConstraintsResourceEnum.LDAP_Configuration}`,
        settingsName: `${ConstraintsSettingsNameEnum.LDAP_retrieve_user_name}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.LDAPConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.LDAP_RetrieveUserName}`,
      },
      {
        id: `${SubfeatureIdEnum.LDAP_retrieve_user_group}`,
        attribute: `${FleetSvcPolicyAttributeId.LDAP_Setup}.retrieve-group`,
        type: `${SubFeatureControlTypeEnum.Textbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READWRITE}`,
        indent: SubfeatureIndentEnum.Indent_Level_1,
        label: 'retrieve-group',
        placeholder: 'name_hint',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.LDAP_retrieve_user_group}`,
        resource: `${ConstraintsResourceEnum.LDAP_Configuration}`,
        settingsName: `${ConstraintsSettingsNameEnum.LDAP_retrieve_user_group}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.LDAPConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.LDAP_RetrieveUserGroup}`,
      },
      {
        id: `${SubfeatureIdEnum.LDAP_exact_match_on_group_attribute}`,
        attribute: `${FleetSvcPolicyAttributeId.LDAP_Setup}.exact-match`,
        type: `${SubFeatureControlTypeEnum.Checkbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READWRITE}`,
        indent: SubfeatureIndentEnum.Indent_Level_1,
        label: 'exact-match',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.LDAP_exact_match_on_group_attribute}`,
        resource: `${ConstraintsResourceEnum.LDAP_Configuration}`,
        settingsName: `${ConstraintsSettingsNameEnum.LDAP_exact_match_on_group_attribute}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.LDAPConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.LDAP_ExactMatchOnGroupAttribute}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.PJL_Password}`,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.PJL_Password}`,
        attribute: `${FleetSvcPolicyAttributeId.PJL_Password}.value`,
        type: `${SubFeatureControlTypeEnum.Password}`,
        accessMode: `${SubFeatureAccessModeEnum.READWRITE}`,
        confirmpassword: true,
        label: 'pjlpassword_label',
        info: 'pjlpassword_info',
        confirmPasswordLabel: 'confirmpassword_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.PJL_Password}`,
        resource: `${ConstraintsResourceEnum.PrintConfiguration}`,
        settingsName: `${ConstraintsSettingsNameEnum.PJL_Password}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.PrintConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.PrintconfigPJLPasswordConfigured}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.Remote_Configuration_Password}`,
    visible: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Remote_Configuration_Password_Check}`,
        visible: false,
        attribute: `${FleetSvcPolicyAttributeId.Remote_Configuration_Password}.check`,
        type: `${SubFeatureControlTypeEnum.Toggle}`,
        accessMode: `${SubFeatureAccessModeEnum.READWRITE}`,
        label: 'remoteConfigPasswordEnable_label',
        info: 'remoteConfigPasswordEnable_info',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Remote_Configuration_Password}`,
        resource: `${ConstraintsResourceEnum.RemoteConfig_Password}`,
        settingsName: `${ConstraintsSettingsNameEnum.RemoteConfigPassword}`,
      },
      {
        id: `${SubfeatureIdEnum.Remote_Configuration_Password_Configured}`,
        visible: false,
        attribute: `${FleetSvcPolicyAttributeId.Remote_Configuration_Password}.value`,
        type: `${SubFeatureControlTypeEnum.Password}`,
        accessMode: `${SubFeatureAccessModeEnum.READWRITE}`,
        label: 'remoteConfigPassword_label',
        info: 'remoteConfigPassword_info',
        hint: 'remoteConfigPassword_hint',
        help: 'remoteConfigPassword_help',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Remote_Configuration_Password}`,
        resource: `${ConstraintsResourceEnum.RemoteConfig_Password}`,
        settingsName: `${ConstraintsSettingsNameEnum.RemoteConfigPasswordConfigured}`,
      },
      {
        id: `${SubfeatureIdEnum.Remote_Configuration_Password_Minimum_Length}`,
        visible: false,
        attribute: `${FleetSvcPolicyAttributeId.Remote_Configuration_Password}.min-length`,
        type: `${SubFeatureControlTypeEnum.Textbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READWRITE}`,
        label: 'rmcfpassMinLen_label',
        info: 'rmcfpassMinLen_info',
        hint: 'rmcfpassMinLen_hint',
        help: 'rmcfpassMinLen_help',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Remote_ConfigPasswordMinimumLength}`,
        resource: `${ConstraintsResourceEnum.RemoteConfig_Password}`,
        settingsName: `${ConstraintsSettingsNameEnum.RemoteConfigPasswordMinimumLength}`,
      },
      {
        id: `${SubfeatureIdEnum.Remote_Configuration_Password_Complexity}`,
        visible: false,
        attribute: `${FleetSvcPolicyAttributeId.Remote_Configuration_Password}.complexity`,
        type: `${SubFeatureControlTypeEnum.Toggle}`,
        accessMode: `${SubFeatureAccessModeEnum.READWRITE}`,
        label: 'rmcfpassComplexity_label',
        info: 'rmcfpassComplexity_info',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Remote_ConfigPasswordComplexity}`,
        resource: `${ConstraintsResourceEnum.RemoteConfig_Password}`,
        settingsName: `${ConstraintsSettingsNameEnum.RemoteConfigPasswordComplexity}`,
      },
      {
        id: `${SubfeatureIdEnum.Remote_Configuration_Password_Account_Lockout}`,
        visible: false,
        attribute: `${FleetSvcPolicyAttributeId.Remote_Configuration_Password}.lockout`,
        type: `${SubFeatureControlTypeEnum.Toggle}`,
        accessMode: `${SubFeatureAccessModeEnum.READWRITE}`,
        label: 'rmcfpassAccLockout_label',
        info: 'rmcfpassAccLockout_info',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Remote_ConfigPasswordAccountLockout}`,
        resource: `${ConstraintsResourceEnum.RemoteConfig_Password}`,
        settingsName: `${ConstraintsSettingsNameEnum.RemoteConfigPasswordAccountLockout}`,
      },
      {
        id: `${SubfeatureIdEnum.Remote_Configuration_Password_MaxAttempts}`,
        visible: false,
        attribute: `${FleetSvcPolicyAttributeId.Remote_Configuration_Password}.max-attempts`,
        type: `${SubFeatureControlTypeEnum.Textbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READWRITE}`,
        label: 'rmcfpassMaxAttempts_label',
        info: 'rmcfpassMaxAttempts_info',
        hint: 'rmcfpassMaxAttempts_hint',
        help: 'rmcfpassMaxAttempts_help',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Remote_ConfigPasswordMaximumAttempts}`,
        resource: `${ConstraintsResourceEnum.RemoteConfig_Password}`,
        settingsName: `${ConstraintsSettingsNameEnum.RemoteConfigPasswordMaximumAttempts}`,
      },
      {
        id: `${SubfeatureIdEnum.Remote_Configuration_Password_Reset_After_Secs}`,
        visible: false,
        attribute: `${FleetSvcPolicyAttributeId.Remote_Configuration_Password}.reset-after`,
        type: `${SubFeatureControlTypeEnum.Textbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READWRITE}`,
        label: 'rmcfpassResetAfter_label',
        info: 'rmcfpassResetAfter_info',
        hint: 'rmcfpassResetAfter_hint',
        help: 'rmcfpassResetAfter_help',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Remote_ConfigPasswordResetAfterSecs}`,
        resource: `${ConstraintsResourceEnum.RemoteConfig_Password}`,
        settingsName: `${ConstraintsSettingsNameEnum.RemoteConfigPasswordResetAfter}`,
      },
      {
        id: `${SubfeatureIdEnum.Remote_Configuration_Password_Lockout_Duration}`,
        visible: false,
        attribute: `${FleetSvcPolicyAttributeId.Remote_Configuration_Password}.lockout-duration`,
        type: `${SubFeatureControlTypeEnum.Textbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READWRITE}`,
        label: 'rmcfpassLockoutDuration_label',
        info: 'rmcfpassLockoutDuration_info',
        hint: 'rmcfpassLockoutDuration_hint',
        help: 'rmcfpassLockoutDuration_help',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Remote_ConfigPasswordLockoutDurationSecs}`,
        resource: `${ConstraintsResourceEnum.RemoteConfig_Password}`,
        settingsName: `${ConstraintsSettingsNameEnum.RemoteConfigPasswordLockoutDuration}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.Service_Access_Code}`,
    entitlementId: `${FeatureEntitlementEnum.advance}`,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Service_Access_Code}`,
        attribute: `${FleetSvcPolicyAttributeId.Service_Access_Code}.value`,
        type: `${SubFeatureControlTypeEnum.Password}`,
        accessMode: `${SubFeatureAccessModeEnum.READWRITE}`,
        confirmpassword: true,
        label: 'svc_label',
        confirmPasswordLabel: 'confirmpassword_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Service_Access_Code}`,
        resource: `${ConstraintsResourceEnum.ServiceUserConfig}`,
        settingsName: `${ConstraintsSettingsNameEnum.SvcAccessCode}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.ServiceUserConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.ServiceUserConfigPasswordConfiguredByUser}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.SNMP_V1_V2}`,
    policyCheck: true,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.SNMP_V1_Enable}`,
        attribute: `${FleetSvcPolicyAttributeId.SNMP_V1_Enable}.check`,
        type: `${SubFeatureControlTypeEnum.Toggle}`,
        accessMode: `${SubFeatureAccessModeEnum.READWRITE_DISABLE}`,
        visible: false,
        label: 'enable_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.SNMPV1V2_Enabled}`,
        resource: `${ConstraintsResourceEnum.SNMPConfig}`,
        settingsName: `${ConstraintsSettingsNameEnum.SNMPV1V2_Enabled}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.SnmpConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.SNMPV1V2ConfigEnabled}`,
      },
      {
        id: `${SubfeatureIdEnum.SNMPV1V2_RW_Access}`,
        attribute: `${FleetSvcPolicyAttributeId.SNMPV1V2_RW_Access}.permission`,
        type: `${SubFeatureControlTypeEnum.Radio}`,
        accessMode: `${SubFeatureAccessModeEnum.READWRITE_DISABLE}`,
        label: 'rwaccess_label',
        hint: 'rwaccess_hint',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.SNMPV1V2_Access_Option}`,
        resource: `${ConstraintsResourceEnum.SNMPConfig}`,
        settingsName: `${ConstraintsSettingsNameEnum.SNMPV1V2_Access_Option}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.SnmpConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.SNMPV1V2ConfigAccessOption}`,
      },
      {
        id: `${SubfeatureIdEnum.SNMPV1V2_Read_Community_Name_Password}`,
        attribute: `${FleetSvcPolicyAttributeId.SNMPV1V2_Read_Community_Name_Password}.read-password`,
        type: `${SubFeatureControlTypeEnum.Password}`,
        accessMode: `${SubFeatureAccessModeEnum.READWRITE}`,
        indent: SubfeatureIndentEnum.Indent_Level_1,
        confirmpassword: true,
        confirmPasswordLabel: 'read-password-confirmpassword_label',
        label: 'read-password_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.SNMPV1V2_ReadOnly_CommunityName}`,
        resource: `${ConstraintsResourceEnum.SNMPConfig}`,
        settingsName: `${ConstraintsSettingsNameEnum.SNMPV1V2_ReadOnly_CommunityName}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.SnmpConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.SNMPV1V2ReadOnlyCommunityNameSet}`,
      },
      {
        id: `${SubfeatureIdEnum.SNMPV1V2_RW_Community_Name_Password}`,
        attribute: `${FleetSvcPolicyAttributeId.SNMPV1V2_RW_Community_Name_Password}.read-write-password`,
        type: `${SubFeatureControlTypeEnum.Password}`,
        accessMode: `${SubFeatureAccessModeEnum.READWRITE}`,
        indent: SubfeatureIndentEnum.Indent_Level_1,
        confirmpassword: true,
        confirmPasswordLabel: 'read-write-confirmpassword_label',
        label: 'read-write-password_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.SNMPV1V2_WriteOnly_CommunityName}`,
        resource: `${ConstraintsResourceEnum.SNMPConfig}`,
        settingsName: `${ConstraintsSettingsNameEnum.SNMPV1V2_WriteOnly_CommunityName}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.SnmpConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.SNMPV1V2ConfigWriteOnlyCommunityNameSet}`,
      },
      {
        id: `${SubfeatureIdEnum.SNMPV1V2_ReadOnly_Public_Allowed}`,
        attribute: `${FleetSvcPolicyAttributeId.SNMPV1V2_Default_Public}.def-public`,
        type: `${SubFeatureControlTypeEnum.Checkbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READWRITE_DISABLE}`,
        indent: SubfeatureIndentEnum.Indent_Level_1,
        label: 'public_enable_label',
        info: 'public_enable_info',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.SNMPV1V2_ReadOnly_Public_Allowed}`,
        resource: `${ConstraintsResourceEnum.SNMPConfig}`,
        settingsName: `${ConstraintsSettingsNameEnum.SNMPV1V2_ReadOnly_Public_Allowed}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.SnmpConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.SNMPV1V2ConfigReadOnlyPublicAllowed}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.SNMP_V3}`,
    policyCheck: true,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.SNMPV3_Enabled}`,
        attribute: `${FleetSvcPolicyAttributeId.SNMPV3}.check`,
        type: `${SubFeatureControlTypeEnum.Checkbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READWRITE_DISABLE}`,
        label: 'enable_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.SNMPV3_Enabled}`,
        resource: `${ConstraintsResourceEnum.SNMPConfig}`,
        settingsName: `${ConstraintsSettingsNameEnum.SNMPV3_Enabled}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.SnmpConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.SNMPV3Configenabled}`,
      },
      {
        id: `${SubfeatureIdEnum.SNMPV3_Username}`,
        attribute: `${FleetSvcPolicyAttributeId.SNMPV3}.user-name`,
        type: `${SubFeatureControlTypeEnum.Textbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READWRITE}`,
        indent: SubfeatureIndentEnum.Indent_Level_1,
        label: 'username_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.SNMPV3_UserName}`,
        resource: `${ConstraintsResourceEnum.SNMPConfig}`,
        settingsName: `${ConstraintsSettingsNameEnum.SNMPV3_UserName}`,
      },
      {
        id: `${SubfeatureIdEnum.SNMPV3_Authentication_Passphrase}`,
        attribute: `${FleetSvcPolicyAttributeId.SNMPV3}.auth-passphrase`,
        type: `${SubFeatureControlTypeEnum.Password}`,
        accessMode: `${SubFeatureAccessModeEnum.READWRITE}`,
        indent: SubfeatureIndentEnum.Indent_Level_1,
        confirmpassword: true,
        confirmPasswordLabel: 'authpass-confirmpassword_label',
        label: 'authpass_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.SNMPV3_Authentication_Passphrase}`,
        resource: `${ConstraintsResourceEnum.SNMPConfig}`,
        settingsName: `${ConstraintsSettingsNameEnum.SNMPV3_Authentication_Passphrase}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.SnmpConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.SNMPV3Configenabled}`,
      },
      {
        id: `${SubfeatureIdEnum.SNMPV3_Privacy_Passphrase}`,
        attribute: `${FleetSvcPolicyAttributeId.SNMPV3}.privacy-passphrase`,
        type: `${SubFeatureControlTypeEnum.Password}`,
        accessMode: `${SubFeatureAccessModeEnum.READWRITE}`,
        indent: SubfeatureIndentEnum.Indent_Level_1,
        confirmpassword: true,
        confirmPasswordLabel: 'privacypass-confirmpassword_label',
        label: 'privacypass_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.SNMPV3_Privacy_Passphrase}`,
        resource: `${ConstraintsResourceEnum.SNMPConfig}`,
        settingsName: `${ConstraintsSettingsNameEnum.SNMPV3_Privacy_Passphrase}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.SnmpConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.SNMPV3Configenabled}`,
      },
      {
        id: `${SubfeatureIdEnum.SNMPV3_Encryption_Algorithms}`,
        attribute: `${FleetSvcPolicyAttributeId.SNMPV3}.encryption-algorithm`,
        type: `${SubFeatureControlTypeEnum.Dropbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READWRITE_DISABLE}`,
        indent: SubfeatureIndentEnum.Indent_Level_1,
        label: 'privacyAlgorithms_label',
        info: 'privacyAlgorithms_info',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.SNMPV3_Encryption_Algorithms}`,
        resource: `${ConstraintsResourceEnum.SNMPConfig}`,
        settingsName: `${ConstraintsSettingsNameEnum.SNMPV3_Encryption_Algorithms}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.SnmpConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.SNMPV3ConfigAuthenticationAlgorithm}`,
      },
      {
        id: `${SubfeatureIdEnum.SNMPV3_MinimumPassword_Length}`,
        attribute: `${FleetSvcPolicyAttributeId.SNMPV3}.min-length`,
        type: `${SubFeatureControlTypeEnum.Textbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READWRITE_DISABLE}`,
        indent: SubfeatureIndentEnum.Indent_Level_1,
        label: 'minPassLength_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.SNMPV3_MinimumPassword_Length}`,
        resource: `${ConstraintsResourceEnum.SNMPConfig}`,
        settingsName: `${ConstraintsSettingsNameEnum.SNMPV3_MinimumPassword_Length}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.SnmpConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.SNMPV3ConfigMinPasswordLength}`,
      },
      {
        id: `${SubfeatureIdEnum.SNMPV3_Password_Complexity_Enabled}`,
        attribute: `${FleetSvcPolicyAttributeId.SNMPV3}.complexity`,
        type: `${SubFeatureControlTypeEnum.Checkbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READWRITE_DISABLE}`,
        indent: SubfeatureIndentEnum.Indent_Level_1,
        label: 'PassComplexity_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.SNMPV3_Password_Complexity}`,
        resource: `${ConstraintsResourceEnum.SNMPConfig}`,
        settingsName: `${ConstraintsSettingsNameEnum.SNMPV3_Password_Complexity_Enabled}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.SnmpConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.SNMPV3ConfigPasswordComplexityEnabled}`,
      },
      {
        id: `${SubfeatureIdEnum.SNMPV3_Account_Lockout}`,
        attribute: `${FleetSvcPolicyAttributeId.SNMPV3}.lockout`,
        type: `${SubFeatureControlTypeEnum.Checkbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READWRITE_DISABLE}`,
        indent: SubfeatureIndentEnum.Indent_Level_1,
        label: 'snmpv3AccountLockout_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.SNMPV3_Account_Lockout}`,
        resource: `${ConstraintsResourceEnum.SNMPConfig}`,
        settingsName: `${ConstraintsSettingsNameEnum.SNMPV3_Account_Lockout}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.SnmpConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.SNMPV3ConfigAccountLockoutEnabled}`,
      },
      {
        id: `${SubfeatureIdEnum.SNMPV3_Maximum_Attempts}`,
        attribute: `${FleetSvcPolicyAttributeId.SNMPV3}.max-attempts`,
        type: `${SubFeatureControlTypeEnum.Textbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READWRITE_DISABLE}`,
        indent: SubfeatureIndentEnum.Indent_Level_2,
        label: 'snmpv3MaxAttempts_label',
        info: 'snmpv3MaxAttempts_info',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.SNMPV3_Maximum_Attempts}`,
        resource: `${ConstraintsResourceEnum.SNMPConfig}`,
        settingsName: `${ConstraintsSettingsNameEnum.SNMPV3_Maximum_Attempts}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.SnmpConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.SNMPV3ConfigAccountLockoutMaxAttempt}`,
      },
      {
        id: `${SubfeatureIdEnum.SNMPV3_Account_Reset_Lockout_Intervel}`,
        attribute: `${FleetSvcPolicyAttributeId.SNMPV3}.reset-after`,
        type: `${SubFeatureControlTypeEnum.Textbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READWRITE_DISABLE}`,
        indent: SubfeatureIndentEnum.Indent_Level_2,
        label: 'snmpv3_accountRestLockout_label',
        info: 'snmpv3_accountRestLockout_info',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.SNMPV3_Account_Reset_Lockout_Intervel}`,
        resource: `${ConstraintsResourceEnum.SNMPConfig}`,
        settingsName: `${ConstraintsSettingsNameEnum.SNMPV3_Lockout_Duration}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.SnmpConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.SNMPV3ConfigResetLockoutCounterInterval}`,
      },
      {
        id: `${SubfeatureIdEnum.SNMPV3_Lockout_Duration}`,
        attribute: `${FleetSvcPolicyAttributeId.SNMPV3}.lockout-duration`,
        type: `${SubFeatureControlTypeEnum.Textbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READWRITE_DISABLE}`,
        indent: SubfeatureIndentEnum.Indent_Level_2,
        label: 'snmpv3_accountLockoutDuration_label',
        info: 'snmpv3_accountRestLockoutDuration_info',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.SNMPV3_Lockout_Duration}`,
        resource: `${ConstraintsResourceEnum.SNMPConfig}`,
        settingsName: `${ConstraintsSettingsNameEnum.SNMPV3_Account_Reset_Lockout_Intervel}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.SnmpConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.SNMPV3ConfigLockoutInterval}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.AccessControl}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.PermissionSets}`,
        attribute: `${FleetSvcPolicyAttributeId.PermissionSets}.roles`,
        type: `${SubFeatureControlTypeEnum.Table}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'permission-sets_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Permission_Sets}`,
        resource: `${ConstraintsResourceEnum.Permission_sets}`,
        settingsName: `${ConstraintsSettingsNameEnum.RoleForPermissionSets}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.PermissionSets}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.PermissionSets}`,
      },
      {
        id: `${SubfeatureIdEnum.SignIn_Method}`,
        attribute: `${FleetSvcPolicyAttributeId.Access}.ctrl-panel-signin`,
        type: `${SubFeatureControlTypeEnum.Toggle}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'defaultControlPannel_label',
        header : 'defaultControlPannel_header',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.AccessControlDefault}`,
        resource: `${ConstraintsResourceEnum.accesControlDefault}`,
        settingsName: `${ConstraintsSettingsNameEnum.AccessControlDefault}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.AccesControlDefault}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.AccessControlDefault}`,
      }, 
      {
        id: `${SubfeatureIdEnum.ControlPanelAccessTable}`,
        attribute: `${FleetSvcPolicyAttributeId.AccessControlTable}.ctrl-panel-permissions`,
        type: `${SubFeatureControlTypeEnum.Table}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'control-Panel-Access-Table_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.AccessControlPanelTable}`,
        resource: `${ConstraintsResourceEnum.ControlPanelTable}`,
        settingsName: `${ConstraintsSettingsNameEnum.ControlPanelAccessTable}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.ControlPanelTable}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.ControlPanelAccessTable}`,
      },       
      {
        id: `${SubfeatureIdEnum.Ews_Access}`,
        attribute: `${FleetSvcPolicyAttributeId.EWSSignIn}.ews-sign-in`,
        type: `${SubFeatureControlTypeEnum.Toggle}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'defaultEWS_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.AccessEWSDefaault}`,
        resource: `${ConstraintsResourceEnum.accesControlDefault}`,
        settingsName: `${ConstraintsSettingsNameEnum.AccessEWSDefaault}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.AccesControlDefault}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.AccessEWSDefault}`,
      },
      {
        id: `${SubfeatureIdEnum.EWS_AccessControlTable}`,
        attribute: `${FleetSvcPolicyAttributeId.AccessControlTable}.ews-permissions`,
        type: `${SubFeatureControlTypeEnum.Table}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'ews-Access-Table_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.EWSAcessControlTable}`,
        resource: `${ConstraintsResourceEnum.ControlPanelTable}`,
        settingsName: `${ConstraintsSettingsNameEnum.ControlPanelAccessTable}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.ControlPanelTable}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.ControlPanelAccessTable}`,
      }, 
      {
        id: `${SubfeatureIdEnum.AlternateSignIn}`,
        attribute: `${FleetSvcPolicyAttributeId.Access}.allow-alt-signin`,
        type: `${SubFeatureControlTypeEnum.Toggle}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'alternate-sign-in-method_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.AccessControlAlternateSignInMethod}`,
        resource: `${ConstraintsResourceEnum.accesControlDefault}`,
        settingsName: `${ConstraintsSettingsNameEnum.AlternateSignInMethod}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.AccesControlDefault}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.AlternateSignInMethod}`,
      },
      {
        id: `${SubfeatureIdEnum.AutomaticSignOut}`,
        attribute: `${FleetSvcPolicyAttributeId.AutoSignOut}.auto-sign-out`,
        type: `${SubFeatureControlTypeEnum.Toggle}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'automatically-sign-in_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.AutomaticallySignIn}`,
        resource: `${ConstraintsResourceEnum.ControlPanel_Configuration}`,
        settingsName: `${ConstraintsSettingsNameEnum.AutomaticallySignOUt}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.ControlPanelResource}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.AutomaticallySignOut}`,
      },
      {
        id: `${SubfeatureIdEnum.SessionTimeOut}`,
        attribute: `${FleetSvcPolicyAttributeId.AutoSignOut}.sign-out-after`,
        type: `${SubFeatureControlTypeEnum.Toggle}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'sign-out_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.SessionTimeOut}`,
        resource: `${ConstraintsResourceEnum.ControlPanel_Configuration}`,
        settingsName: `${ConstraintsSettingsNameEnum.AutomaticallySignOUt}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.ControlPanelResource}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.AutomaticallySignOut}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.Bootloader_Password}`,
    entitlementId: `${FeatureEntitlementEnum.advance}`,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Bootloader_Password}`,
        type: `${SubFeatureControlTypeEnum.Toggle}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'bootloader-password_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Bootloader_Password}`,
        resource: `${ConstraintsResourceEnum.Bootloader_Password}`,
        settingsName: `${ConstraintsSettingsNameEnum.Bootloader_Password}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.BootloaderConfiguration}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.Bootloader_Password}`,
      },
      {
        id: `${SubfeatureIdEnum.Bootloader_Current_Password}`,
        attribute: `${FleetSvcPolicyAttributeId.Bootloader_Password}.current`,
        type: `${SubFeatureControlTypeEnum.Password}`,
        accessMode: `${SubFeatureAccessModeEnum.WRITEONLY}`,
        confirmpassword: false,
        label: 'currentpassword_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Bootloader_Current_Password}`,
        resource: `${ConstraintsResourceEnum.Bootloader_Password}`,
        settingsName: `${ConstraintsSettingsNameEnum.Bootloader_Current_Password}`,
      },
      {
        id: `${SubfeatureIdEnum.Bootloader_Proposed_Password}`,
        attribute: `${FleetSvcPolicyAttributeId.Bootloader_Password}.new`,
        type: `${SubFeatureControlTypeEnum.Password}`,
        accessMode: `${SubFeatureAccessModeEnum.WRITEONLY}`,
        confirmpassword: true,
        label: 'newpassword_label',
        confirmPasswordLabel: 'confirmpassword_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Bootloader_Proposed_Password}`,
        resource: `${ConstraintsResourceEnum.Bootloader_Password}`,
        settingsName: `${ConstraintsSettingsNameEnum.Bootloader_Proposed_Password}`,
      },
    ],
  },
  {
    id: `${FeatureIdEnum.Digital_Sending_Service}`,
    configurable: false,
    subfeatures: [
      {
        id: `${SubfeatureIdEnum.Digital_Sending_Service_AllowUse}`,
        attribute: `${FleetSvcPolicyAttributeId.Digital_Sending_Service}.use`,
        type: `${SubFeatureControlTypeEnum.Checkbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'digital-sending-service-allow-use_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Digital_Sending_Service_AllowUse}`,
        resource: `${ConstraintsResourceEnum.FleetProxyLegacyAttributes}`,
        settingsName: `${ConstraintsSettingsNameEnum.Digital_Sending_Service_AllowUse}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.FleetProxyLegacyAttributes}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.DigitalSendingServiceAllowUse}`,
      },
      {
        id: `${SubfeatureIdEnum.Digital_Sending_Service_AllowTransfer}`,
        attribute: `${FleetSvcPolicyAttributeId.Digital_Sending_Service}.transfer`,
        type: `${SubFeatureControlTypeEnum.Checkbox}`,
        accessMode: `${SubFeatureAccessModeEnum.READONLY}`,
        label: 'digital-sending-service-allow-transfer_label',
        subFeatureId: `${ConstraintsSubFeatureIdEnum.Digital_Sending_Service_AllowTransfer}`,
        resource: `${ConstraintsResourceEnum.FleetProxyLegacyAttributes}`,
        settingsName: `${ConstraintsSettingsNameEnum.Digital_Sending_Service_AllowTransfer}`,
        serviceGunData: `${DeviceCacheServiceGunEnum.FleetProxyLegacyAttributes}`,
        serviceSettingsData: `${DeviceCacheCdmDataEnum.DigitalSendingServiceAllowTransfer}`,
      },
    ],
  },
];
