import { RootProvider } from '@jarvis/react-portal-addons';
import devicePluginModelNumber from '@manageability/constraints/plugInNameToModelNumber.json';
import sleep from '@react-corekit/sleep';
import React, { useContext, useEffect, useState, version } from 'react';
import { useDispatch } from 'react-redux';
import { fleetMgtSvc } from '../../../src/api/FleetMgtSvc';
import apiController from '../../../src/api/apiController';
import { storeDeviceId } from '../../../src/store/config/action';
import { getConfigStoreActions } from '../../../src/store/config/init';
import {
  resetInitialData,
  setControlledPolicyData,
  setControlledPolicyError,
  setControlledPolicyRequest,
} from '../../../src/store/deviceConfig/action';
import { useStoreState } from '../../../src/store/useStoreState';
import { service } from '../../../src/utils/constants';
import { useConstructor } from '../../../src/utils/customHook';
import { mockDeviceCacheResponse } from '../../../tests/mocks/deviceCacheResponse';
import resources from '../../assets/locale/index';
import BatchModeDeviceConfigParser from '../../dataParser/BatchModeDeviceConfigParser';
import DeviceConfigParser from '../../dataParser/DeviceConfigParser';
import GetControlledPolicyHelper from '../../helper/GetControlledPolicyHelper';
import GetFeaturesDataHelper from '../../helper/GetFeaturesDataHelper';
import useDeviceCache from '../../hooks/useDeviceCache';
import useDeviceAppCache from '../../hooks/useDeviceCacheForApp';
import useContentBroker from '../../hooks/useContentBroker';
import { shellProps } from '../../shellProps';
import logger from '../../utils/logger';
import BatchView from '../BatchView/BatchView';
import { DeviceNotExist } from '../CommonExtended/ErrorData/DeviceNotExist';
import { Loader } from '../CommonExtended/Loader/Loader';
import EditView from '../EditView/EditView';
import { AnchorContainer } from '../ReadView/Anchor/Anchor';
import { CategoryContainer } from '../ReadView/Category/CategoryContainer';
import { ContentWrapper, ErrorWrapper, SectionsWrapper } from './Styles';
import { MockEffectivePolicy } from '../../../tests/mocks/effectivePolicy';
import { contentBrokerResponse } from '../../../tests/mocks/ContentBroker.response';
import { appConfigDataResponse } from '../../../tests/mocks/AppConfigData.response';
import { fetchEffectivePolicyForDemoMode,fetchDeviceCacheForDemoMode,fetchContentBrokerForDemoMode,fetchAppConfigForDemoMode} from '../../../tests/mocks/DemoModeAPI';
import { Version } from 'sass';
import VersionTooltip from '../CustomizableComponents/AppsDeployment/VersionTooltip';

export const DeviceConfiguration = (props) => {
  const {demoEnabled, apiPath, demoModeOptions, requestOptions, deviceId } = props;
  const [deviceConstraints, setDeviceConstraints] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editFeatureData, setEditFeatureData] = useState({});
  const [isUnSupportedPlugin, setIsUnSupportedPlugin] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [error, setError] = useState(true);
  const { getCategoryData } = DeviceConfigParser();
  const { getBatchModeModalData } = BatchModeDeviceConfigParser();
  const dispatch = useDispatch();
  const { configState } = useStoreState();
  const { startRootComponent } = configState;
  const dispatchAll = (list: any[]) => list.forEach((e) => dispatch(e));
  const { getFeaturesData } = GetFeaturesDataHelper();
  const [editableConfigToggle, setEditableConfigToggle] = useState(false);
  const [configurableFeatures, setConfigurableFeatures] = useState([]);
  const [deviceCacheResult, setDeviceCacheResult] = useState<any | null>(null);
  const [filteredDeviceModel, setFilteredDeviceModel] = useState([]);
  const {
    showMultipleConfigUi,
    showModal,
    setShowModal,
    selectedDeviceIdList
  } = useContext(shellProps);
  const { getApiClient } = apiController();
  const clientV1 = getApiClient(service.fleetMgtV);
  const { getControlledPolicyData } = GetControlledPolicyHelper();
  const { fetchDeviceResources } = useDeviceCache(props.stack);
  const { fetchDeviceAppCollection } = useDeviceAppCache(props.stack);
  const { fetchContentResources } = useContentBroker(props.stack);

  //   // Todo: store data as format of deviceConstraints[plugInName]
  //   console.log('response.data', pluginData.supportedDeviceResources);
  //   setDeviceConstraints(pluginData.supportedDeviceResources);
  //   localStorage.setItem(
  //     createLocalStorageKey(plugInName),
  //     JSON.stringify(pluginData.supportedDeviceResources),
  //   );
  // };

  // const createLocalStorageKey = (plugInName) => {
  //   return 'DEVCONF_' + plugInName;
  // };

  //seraches and deletes LocalStorage items related to deviceConfiguration MFE in case of version update
  // const searchLocalStorageForPlugins = () => {
  //   for (let i = 0; i < localStorage.length; i++) {
  //     const key = localStorage.key(i);
  //     if (key.substring(0, 7) === 'DEVCONF') {
  //       localStorage.removeItem(key);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   if (localStorage.getItem('DDMFEVersion') !== packageVersion) {
  //     searchLocalStorageForPlugins();
  //     localStorage.setItem('DDMFEVersion', packageVersion);
  //     getFeaturesData(pluginDataArray[0].plugInName);
  //   } else if (!localStorage.getItem(createLocalStorageKey(pluginDataArray[0].plugInName))) {
  //     getFeaturesData(pluginDataArray[0].plugInName);
  //   } else {
  //     const retrievedObject = localStorage.getItem(
  //       createLocalStorageKey(pluginDataArray[0].plugInName),
  //     );
  //     setDeviceConstraints(JSON.parse(retrievedObject));
  //   }
  //   dispatch(savePluginName(pluginDataArray[0].plugInName));
  //   dispatch(storeDeviceId(props.deviceId));
  //   dispatch(resetInitialData());
  // }, []);

  useEffect(() => {
    const pluginDatas = props.makeAndModel.map((data) => {
      const pluginDataName = devicePluginModelNumber.filter((element) =>
        element.deviceModelNumberList.some((deviceModel) => deviceModel == data.number),
      )[0]?.plugInName;

      return { ...data, plugInName: pluginDataName };
    });

    const filteredPluginDatas = pluginDatas.filter(function (element) {
      return element.plugInName !== undefined;
    });

    const checkUnsupportedPlugins = pluginDatas.some(function (element) {
      return element.plugInName == undefined;
    });

    setFilteredDeviceModel(pluginDatas.map((e) => e.number));
    setDeviceConstraints(getFeaturesData(filteredPluginDatas));
    setIsUnSupportedPlugin(checkUnsupportedPlugins);
    dispatch(storeDeviceId(props.deviceId));
    dispatch(resetInitialData());
  }, []);
  useEffect(() => {
    const fetchAppData = async (deviceId) => {
      try {
        const response = await fetchDeviceAppCollection(deviceId);
        let appData;
        if (props.isWex && demoEnabled) {
          appData  = await fetchAppConfigForDemoMode(apiPath, requestOptions, demoModeOptions?.mockNoDevices, 'appConfig');
        } else {
          appData = props.demoMode ? appConfigDataResponse : response;
        }
        return appData?.software?.apps?.map((element) => element.uuid);
      } catch (e) {
        logger.error('Error while fetching device cache API for App Deployment:', e.message);
        throw e;
      }
    };

    const fetchResources = async (deviceData) => {
      try {
        const response = await fetchContentResources(deviceData);
        let contentBrokerData;
        if (props.isWex && demoEnabled) {
          contentBrokerData = await fetchContentBrokerForDemoMode(apiPath, requestOptions, demoModeOptions?.mockNoDevices, 'contentBroker');
        } else {
          contentBrokerData = props.demoMode ? contentBrokerResponse : response;
        }
        
        const resourceList = contentBrokerData?.data?.resourceList || [];
        const groupedResources = resourceList.reduce((acc: { [title: string]: string[] }, item) => {
          const title = item.name;
          if (!title) {
            console.error('Item name is undefined:', item);
            return acc;
          }
          if (!acc[title]) {
            acc[title] = [];
          }
          acc[title].push(item.version);
          return acc;
        }, {});
    
        return Object.entries(groupedResources).map(([title, versions]) => {
          const versionArray = versions as string[]; // Explicitly type versions as string[]
          const displayedVersions = versionArray.slice(0, 2).join(', ') + (versionArray.length > 2 ? '...' : '');
          const tooltipVersions = versionArray.length > 2 ? versionArray.slice(2).join(', ') : null;
          const resourceItem = resourceList.find((item) => item.name === title);
    
          if (!resourceItem) {
            console.error(`Resource item not found for title: ${title}`);
            return null; // or handle it appropriately
          }
    
          return {
            uuid: resourceItem.uuid,
            resourceId: resourceItem.resourceId,
            title: title,
            provider: resourceItem.vendorName,
            version: displayedVersions,
            tooltip: tooltipVersions,
            mocId: Object.entries(resourceItem.metadata)
              .filter(([key, value]) => value['dataKind'] === 'MOC')
              .map(([key, value]) => value['dataId'])[0],
            appIcon: Object.entries(resourceItem.metadata)
              .filter(([key, value]) => value['dataKind'] === 'Icon50x50')
              .map(([key, value]) => value['downloadLink'])[0],
            config: [],
          };
        }).filter(item => item !== null); // Filter out null items
      } catch (e) {
        logger.error('Error while fetching content broker API:', e.message);
        throw e;
      }
    };
    const handleFetch = async () => {
      setIsFetching(true);
      setError(false);
      console.log('Not Undefined');

      try {
        let result;
        let controlledPolicies;
        let deviceCacheData;

        if (showMultipleConfigUi) {
          await sleep(1000);
          result = await fleetMgtSvc.getPolicyDetails(clientV1, selectedDeviceIdList);
             if (result?.response) {
            controlledPolicies = getControlledPolicyData(result.response.data);
            dispatch(setControlledPolicyData(controlledPolicies));
          } else if (props.isWex && demoEnabled) {
            controlledPolicies = getControlledPolicyData(MockEffectivePolicy);
            dispatch(setControlledPolicyData(controlledPolicies));
          } else {
            dispatch(setControlledPolicyError(result?.error));
          }
          const concatenatedApps= props.selectedData
          ?.map((data) => data.software?.apps)
          .filter((apps) => apps && apps.length > 0)
          .reduce((acc, curr) => acc.concat(curr), []);
          const selectedDataForApps = (concatenatedApps?.map((element) => element.uuid) || []);
           const deviceData = await (selectedDataForApps && selectedDataForApps?.length > 0
            ? fetchResources(selectedDataForApps)
            : Promise.resolve({})); 
          await getBatchModeModalData(deviceConstraints, filteredDeviceModel, controlledPolicies, isUnSupportedPlugin,deviceData);
        }
        else {
          await sleep(1000);
          result = await fleetMgtSvc.getPolicyDetails(clientV1, [props.deviceId]);
          if (result?.response) {
            controlledPolicies = getControlledPolicyData(result.response.data);
          } else if (props.isWex && demoEnabled) {
            controlledPolicies =  await fetchEffectivePolicyForDemoMode(apiPath, requestOptions, demoModeOptions?.mockNoDevices, 'effectivePolicy');
          }else {
            controlledPolicies = result?.error;
          }
          const response = await fetchDeviceResources(props.deviceId);
          if (props.isWex && demoEnabled) {
            deviceCacheData = await fetchDeviceCacheForDemoMode(apiPath, requestOptions, demoModeOptions?.mockNoDevices, 'deviceCache');
          } else {
            deviceCacheData = props.demoMode ? mockDeviceCacheResponse : response;
          }
          if (deviceCacheData == null) throw new Error('deviceCacheData API Response is null.');
          setDeviceCacheResult(deviceCacheData);
          await fetchAppData(props.deviceId)
            .then((appData) => {
              if (appData && appData.length > 0) {
                return fetchResources(appData);
              } else {
                return Promise.resolve({}); // Return an empty object or appropriate default value
              }
            })
            .then((deviceData) => {
              return getCategoryData(
                deviceConstraints,
                filteredDeviceModel,
                deviceCacheData,
                isUnSupportedPlugin,
                deviceData,
                controlledPolicies
              );
            })
            .catch((e) => {
              logger.error('Error while fetching API for WX portal:', e.message);
              throw e;
            });
        }
        setIsFetching(false);
        setError(false);
      } catch (e) {
        logger.error('Error while fetching:', e.message);
        setIsFetching(false);
        setError(true);
      }
    };

    if (deviceConstraints.length) {
      handleFetch();
    }
  }, [props.deviceId, deviceConstraints, setDeviceConstraints, isUnSupportedPlugin]);

  useConstructor(() => {
    dispatchAll(getConfigStoreActions(props));
  });

  const handleOnEditClick = (featureData) => {
    setShowEditModal(true);
    setEditFeatureData(featureData);
  };
  const render = () => {
    return showMultipleConfigUi ? (
      <>
        {showModal && !isFetching && !error && (
          <BatchView
            showModal={showModal}
            setShowModal={setShowModal}
            error={error}
            handleUseToast={props.handleUseToast}
            data-testid={'id-batch-view'}
            selectedData={props.selectedData}
            {...props}
          />
        )}
      </>
    ) : (
      <>
        {isFetching ? (
          <Loader />
        ) : (
          <>
            {error ? (
              <ErrorWrapper>
                <DeviceNotExist />
              </ErrorWrapper>
            ) : (
              <>
                {
                  <ContentWrapper>
                    <SectionsWrapper>
                      <CategoryContainer
                        deviceCacheResult={deviceCacheResult}
                        editableConfigToggle={editableConfigToggle}
                        setEditableConfigToggle={setEditableConfigToggle}
                        setConfigurableFeatures={setConfigurableFeatures}
                        handleOnEditClick={handleOnEditClick}
                        data-testid={'id-category-container'}
                        entitlements={props.entitlements}
                        {...props}
                      />
                    </SectionsWrapper>
                    <AnchorContainer configurableFeatures={configurableFeatures} />
                    {showEditModal ? (
                      <EditView
                        showModal={showEditModal}
                        setShowModal={setShowEditModal}
                        featuresData={editFeatureData}
                        data-testid={'id-edit-view'}
                        entitlements={props.entitlements}
                        {...props}
                      />
                    ) : null}
                  </ContentWrapper>
                }
              </>
            )}
          </>
        )}
      </>
    );
  };

  return startRootComponent && <RootProvider localization={props.localization} resources={resources}>{render()}</RootProvider>;
};
