import tokens from '@veneer/tokens';
import styled from 'styled-components';
import { colorGray10 } from '@veneer/tokens/dist/tokens/tokens';

export const CustomParentHeading = styled.div`
color: ${colorGray10};
font-family: ${tokens.fontTextMedium};
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 20px; 
`;
export const CustomChildHeading = styled.div`
color: ${colorGray10};
font-family: ${tokens.fontTextMedium};
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px;
margin-left: 16px;
`;
