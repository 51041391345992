import { useDispatch } from 'react-redux';
import { ConstraintsScopeKeyEnum } from '../config/ConstraintsEnums';
import { SubfeatureIdMap } from '../dataModel/SubfeatureIdMap';
import { setRulesUpdate } from '../store/deviceConfig/action';

const ConstraintsRuleParser = () => {
  const dispatch = useDispatch();
  const mapFilter = (resource, setting, schemaVal) => {
    const SubfeatureIds = SubfeatureIdMap.filter(
      (item) => item.settingsName === setting && item.resource === resource,
    )?.map((obj) => ({ ...obj, schemaVal, valueCheck: true }));

    return SubfeatureIds;
  };

  let ruleMap = [];

  const validateIfRuleExists = (constraints, currentSettingName) => {
    constraints?.rules?.forEach((rule) => {
      const effect = rule.effect;

      if (rule.condition) {
        Object.keys(rule.condition).forEach(function eachKey(key) {
          const conditionScopes = rule.condition[key];
          const filteredScopeVal = conditionScopes?.map((conditionScopeItem) => {
            if (!conditionScopeItem || !conditionScopeItem.schema) {
              return null;
            }

            const scope = conditionScopeItem.scope;
            const schema = Object.keys(conditionScopeItem.schema)[0];
            const schemaVal = conditionScopeItem.schema[schema];

            if (!scope) {
              return null;
            }

            const scopeSplitVal = scope.split('/');
            const scopeResource = scopeSplitVal[2];
            const scopeSetting = scopeSplitVal[4];

            let schemaValue;
            if (!Array.isArray(schemaVal)) {
              schemaValue = schemaVal;
            } else {
              schemaValue = schemaVal.map((val) => val);
            }

            let mapFilterVal = mapFilter(scopeResource, scopeSetting, schemaValue);
            return mapFilterVal;
          }).filter(Boolean); // Filter out null values
          const dependsOn = [].concat.apply([], filteredScopeVal);

          const ruleObj = {
            ruleType: key,
            effect: effect,
            currentSettingName,
          };

          ruleMap = [Object.assign(ruleObj, { dependsOn })];
        });
      }
    });
    return ruleMap;
  };

  const tempConditionCheck = (rules, settings) => {
    let rulesPayload;

    const getBooleanString = (value) => {
      if (value === true) return 'true';
      if (value === false) return 'false';
      return value;
    };
    const updateRulesPayload = (settingsData, valueCheck) => {
      if (settingsData.tempCheck) {
      rulesPayload = { settingsName: settingsData.settingsName, valueCheck };
      } else {
        dispatch(setRulesUpdate({ settingsName: settingsData.settingsName, valueCheck }));
      }
    };

    const condionCheck = (scope, settingsData) => {
      let valueCheck = false;
      scope?.forEach((scopeItem) => {
        const isSameSettingsName = scopeItem.settingsName === settingsData?.settingsName;
        const isSameResource = scopeItem.resource === settingsData?.resource;
        if (isSameSettingsName && isSameResource) {
          const schemaValue = getBooleanString(scopeItem.schemaVal);
          const fleetValue = getBooleanString(settingsData?.fleetValue);
          if (Array.isArray(schemaValue)) {
            valueCheck = schemaValue.includes(fleetValue);
          } else {
            valueCheck = schemaValue === fleetValue;
          }
          updateRulesPayload(settingsData, valueCheck);
        }
      });
      return scope;
    };
    rules?.map((rulesDataMap) => {
      condionCheck(rulesDataMap.dependsOn, settings);
    });

    return rulesPayload;
  };

  const setRuleEffect = (rules) => {
    let currentSettingName = '';
    let effect = '';
    const ruleEffects = rules
      ?.map((rulesDataMap) => {
        const scopes = rulesDataMap.dependsOn;
        if (rulesDataMap.ruleType === ConstraintsScopeKeyEnum.ALLOF) {
          const containsManualAndAdvanced = Array.isArray(scopes?.[1]) && scopes[1].some(
            (scopeItem) => scopeItem.valueCheck === true
          );
          if (containsManualAndAdvanced === true) {
            effect = undefined;
          } else {
            const allOfTrue =
              Array.isArray(scopes) && scopes.filter((scopesItem) => scopesItem.valueCheck === true).length === scopes.length;
            if (!allOfTrue) {
              currentSettingName = rulesDataMap.currentSettingName;
              effect = rulesDataMap.effect;
              return { currentSettingName, effect };
            }
          }
        }
        if (rulesDataMap.ruleType === ConstraintsScopeKeyEnum.ANYOF) {
          const anyOfTrue =
            scopes?.filter((scopesItem) => scopesItem.valueCheck === true).length >= 1;
          if (!anyOfTrue) {
            currentSettingName = rulesDataMap.currentSettingName;
            effect = rulesDataMap.effect;
            return { currentSettingName, effect };
          }
        }
        if (rulesDataMap.ruleType === ConstraintsScopeKeyEnum.ONEOF) {
          const oneOfTrue =
            scopes?.filter((scopesItem) => scopesItem.valueCheck === true).length === 1;
          if (!oneOfTrue) {
            currentSettingName = rulesDataMap.currentSettingName;
            effect = rulesDataMap.effect;
            return { currentSettingName, effect };
          }
        }
      })
      ?.filter(function (element) {
        return element !== undefined;
      });
    return ruleEffects ? ruleEffects : [];
  };

  return {
    validateIfRuleExists,
    tempConditionCheck,
    setRuleEffect,
  };
};

export default ConstraintsRuleParser;