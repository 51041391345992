import { DeviceCacheServiceGunEnum } from '../config/DeviceCacheServiceGunEnums';

const GetCustomDeviceCacheValueHelper = (value, fleetdata) => {
  let result;
  switch (value) {
    case DeviceCacheServiceGunEnum.Eth0_DeviceAdapterConfiguration:
      if (fleetdata?.cdmData?.ethConfig?.dot1xAuthConfig?.blockNetworkOnAuthFailure != undefined) {
        result = fleetdata?.cdmData?.ethConfig?.dot1xAuthConfig?.blockNetworkOnAuthFailure;
      } else {
        result = false;
      }
      break;
    case DeviceCacheServiceGunEnum.DeviceAdminConfig:
      if (fleetdata?.cdmData?.passwordSet != undefined) {
        result = fleetdata?.cdmData?.passwordSet;
      } else if (fleetdata?.cdmData?.currentPasswordLength != undefined) {
        if (fleetdata?.cdmData?.currentPasswordLength > 0) {
          result = true;
        } else {
          result = false;
        }
      } else {
        result = fleetdata?.cdmData?.configuredByUser;
      }
      break;
    // case DeviceCacheServiceGunEnum.EmailAddressAndMessageSetting:
    //   result = fleetdata?.cdmData?.dest?.email?.addressFieldRestrictionsEnabled;
    //   if (result?.length) {
    //     if (result == 'true') {
    //       result = 'AddressFieldRestricTrue';
    //     } else {
    //       result = 'AddressFieldRestricFalse';
    //     }
    //   } else {
    //     result = 'undefined';
    //   }
    //   break;
    case DeviceCacheServiceGunEnum.SnmpConfiguration:
      if (fleetdata?.cdmData?.snmpV1V2Config?.enabled != undefined) {
        result =
          fleetdata?.cdmData?.snmpV1V2Config?.enabled === 'true'
            ? fleetdata?.cdmData?.snmpV1V2Config?.accessOption
            : 'disable';
      } else {
        result = 'undefined';
      }
      break;
      case DeviceCacheServiceGunEnum.WifiDirectConfiguration:
        if (fleetdata?.cdmData?.awcActive != undefined) {
          if (fleetdata?.cdmData?.awcActive === "true") {
            result = false
          }
          else if (fleetdata?.cdmData?.awcActive === "false") {
            result = true;
          }
          else {
            result = fleetdata?.cdmData?.awcActive;
          }
        }
        break;
        case DeviceCacheServiceGunEnum.WifiDirectConfiguration:
        if (fleetdata?.cdmData?.showPassphrase != undefined) {
          if (fleetdata?.cdmData?.showPassphrase === "true") {
            result = false;
          }
          else if (fleetdata?.cdmData?.showPassphrase === "false") {
            result = true;
          }
          else {
            result = fleetdata?.cdmData?.showPassphrase;
          }
        }
        break;
    default:
      result = 'undefined';
  }
  return result != undefined ? result : 'undefined';
};
export default GetCustomDeviceCacheValueHelper;
