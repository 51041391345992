import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CategoryEnum } from '../../../config/CategoryEnums';
import LaunchDarklyVariables from '../../../config/LaunchDarklyVariables';
import ConstraintsRuleParser from '../../../dataParser/ConstraintsRuleParser';
import { setRulesEffectData } from '../../../store/deviceConfig/action';
import { useStoreState } from '../../../store/useStoreState';
import { EditableToggle } from '../../CommonExtended/EditableToggle/EditableToggle';
import { CategorySection } from './Section/CategorySection';

export const CategoryContainer = (props) => {
  const { deviceCacheResult, editableConfigToggle, setEditableConfigToggle, setConfigurableFeatures, entitlements } =
    props;
  const { deviceConfigDataState } = useStoreState();
  const { featuresData, settingsData, rulesData } = deviceConfigDataState;
  const [deviceConfigDataCopy, setDeviceConfigDataCopy] = useState([]);
  const { getLaunchDarklyFeatures } = LaunchDarklyVariables(props);
  const { tempConditionCheck, setRuleEffect } = ConstraintsRuleParser();
  const dispatch = useDispatch();
  const sampleRulesData = () => {
    return settingsData.map((settings) => {
      return tempConditionCheck(rulesData, settings);
    });
  };

  useEffect(() => {
    sampleRulesData();
    const LaunchDarklyFeatures = getLaunchDarklyFeatures() ? getLaunchDarklyFeatures() : []
    const filteredLaunchDarklyValues = featuresData.filter((i) =>
      LaunchDarklyFeatures.includes(i.id),
    );
    setDeviceConfigDataCopy(filteredLaunchDarklyValues);
    setConfigurableFeatures(filteredLaunchDarklyValues);
  }, [featuresData, useFlags()]);

  useEffect(() => {
    const editableItems = deviceConfigDataCopy.filter((data) => {
      return data.configurable === undefined || data.configurable;
    });

    const LaunchDarklyFeatures = getLaunchDarklyFeatures() ? getLaunchDarklyFeatures() : []

    const filteredLaunchDarklyValues = featuresData.filter((i) =>
      LaunchDarklyFeatures.includes(i.id),
    );

    if (editableConfigToggle) {
      setDeviceConfigDataCopy(editableItems);
      setConfigurableFeatures(editableItems);
    } else {
      setDeviceConfigDataCopy(filteredLaunchDarklyValues);
      setConfigurableFeatures(filteredLaunchDarklyValues);
    }
  }, [editableConfigToggle]);

  useEffect(() => {
    dispatch(setRulesEffectData(setRuleEffect(rulesData)));
  }, [rulesData]);

  return (
    <div>
      {!editableConfigToggle && !deviceConfigDataCopy.length ? null : (
        <EditableToggle
          editableConfigToggle={editableConfigToggle}
          setEditableConfigToggle={setEditableConfigToggle}
          data-testid={'id-show-editable-items-toggle'}
        />
      )}
     {Object.keys(CategoryEnum).sort((a, b) => CategoryEnum[a].localeCompare(CategoryEnum[b]))
      .map((oneKey, i) => {
        const categoryKey = CategoryEnum[oneKey];
        const categoryData = deviceConfigDataCopy.filter(
          (data) => data.categoryName === categoryKey,
        );
        return categoryData.length ? (
          <CategorySection
            deviceCacheResult={deviceCacheResult}
            deviceConfigData={categoryData}
            categoryKey={categoryKey}
            handleOnEditClick={props.handleOnEditClick}
            entitlements={entitlements}
            isWex = {props.isWex}
          />
        ) : null;
      })}
    </div>
  );
};